import React from "react";
import UserIcon from "assets/svgs/UserLarge.svg";
import { useNavigate } from "react-router-dom";

const InstallerInfo = ({ selected, data, onClick }) => {
  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate(`/installer-profile/${data._id}`);
  };
  return (
    <div
      className={`w-[275px] flex flex-row gap-2 p-4 rounded-md shadow-md cursor-pointer ${
        selected ? "bg-[#EFFFDB]" : "bg-white"
      }`}
      onClick={onClick}
    >
      <div
        className={`h-[48px] w-[48px] flex-none flex items-center justify-center p-2 rounded-full ${
          selected ? "bg-white" : "bg-[#EDF2FF]"
        }`}
      >
        <img
          src={data?.profileImage || UserIcon}
          alt="icon"
          className="w-[24px] h-[26px] rounded-full"
        />
      </div>

      <div className="flex-column gap-1">
        <p className="text-[15px] text-[#0D1C2E] font-[400]">
          {data?.name || "John Doe"}
        </p>
        <p className="text-xs text-gray">
          {data?.companyName || "Doe Limited"}
        </p>
        <p
          onClick={handleProfileClick}
          className="text-primary text-[10px] font-[500] cursor-pointer"
        >
          View Profile
        </p>
      </div>
    </div>
  );
};

export { InstallerInfo };
