import { PageLoader } from 'components/elements';
import { BidCard } from 'components/installer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ProjectsStarted = () => {
  const { id } = useParams();
  const { loading, startedProjects } = useSelector((state) => state.bid);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const project = startedProjects.find((item) => item._id === id);
    setSelectedProject(project);
  }, [id, startedProjects]);

  return loading ? (
    <div className='w-full h-[90vh] m-auto flex items-center justify-center'>
      <PageLoader />
    </div>
  ) : (
    <div className='flex-column gap-8 w-full h-full py-4 md:py-8 px-1'>
      <BidCard
        type='projectStarted'
        site={selectedProject?.siteId}
        finalBid={selectedProject?.cost?.finalBid}
        customer={selectedProject?.customerId}
      />
    </div>
  );
};

export { ProjectsStarted };
