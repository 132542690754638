import React from 'react';
import { useSelector } from 'react-redux';

function TechnologiesList() {
  const { installers: installer } = useSelector((state) => state.installer);
  return (
    <div className='flex flex-col gap-[17px]'>
      <div className='flex flex-column'>
        <p className='text-[#98A2B3] font-bold text-[15px]'>
          Technology Type & Capacities Offered
        </p>
        <p className='text-[#98A2B3] font-[400] text-[15px]'>
          What technologies do you supply along with the minimum and maximum
        </p>
      </div>
      <p className='text-[#98A2B3] font-[400] text-[15px]'></p>
      <div className='grid-container'>
        {installer?.technologies?.length > 0 &&
          installer?.technologies.map(
            ({ technologyType, maxCapacity, minCapacity }) => {
              return (
                <div className='flex flex-col xl:flex-row xl:items-center gap-2'>
                  <p className='text-sm md:text-base text-[#5E6278] font-[500] capitalize'>
                    {technologyType === 'gas chp'
                      ? 'Gas CHP'
                      : technologyType === 'biomass chp'
                      ? 'Biomass CHP'
                      : technologyType}
                  </p>
                  <p className='text-[10px] md:text-xs text-[#749816] bg-[#F4FFD7] rounded-full py-1 w-fit sm:max-w-[150px] px-2 font-[500]'>
                    {minCapacity}-{maxCapacity} kW
                  </p>
                </div>
              );
            }
          )}
      </div>
    </div>
  );
}

export { TechnologiesList };
