import DarkGreen from 'assets/svgs/dashboard/DarkGreen.svg';
import LightGreen from 'assets/svgs/dashboard/LightGreen.svg';
import Purple from 'assets/svgs/dashboard/Purple.svg';
import Blue from 'assets/svgs/dashboard/Blue.svg';
import Orange from 'assets/svgs/dashboard/Orange.svg';

const CREATE_ACCOUNT_STEPS = [
  {
    title: 'Account Details',
    breif: 'Setup Your Account Details',
    isCompleted: false,
  },
  {
    title: 'Company Details',
    breif: 'Setup Your Company Info',
    isCompleted: false,
  },
  {
    title: 'Technologies Offered',
    breif: 'Add Your Technologies',
    isCompleted: false,
  },
  {
    title: 'Region Info',
    breif: 'Select Your Region',
    isCompleted: false,
  },
];

const TECHNOLOGIES = [
  {
    name: 'air source heat',
    title: 'Air Source Heat',
    displayed: false,
    key: 1,
  },
  {
    name: 'battery storage',
    title: 'Battery Storage',
    displayed: false,
    key: 2,
  },
  {
    name: 'biomass chp',
    title: 'Biomass CHP',
    displayed: false,
    key: 3,
  },
  {
    name: 'gas chp',
    title: 'Gas CHP',
    displayed: false,
    key: 4,
  },
  {
    name: 'biomass heat',
    title: 'Biomass Heat',
    displayed: false,
    key: 5,
  },
  {
    name: 'ground source heat',
    title: 'Ground Source Heat',
    displayed: false,
    key: 6,
  },
  {
    name: 'ground mounted solar',
    title: 'Ground Mounted Solar',
    displayed: false,
    key: 7,
  },
  {
    name: 'roof mounted solar',
    title: 'Roof Mounted Solar',
    displayed: false,
    key: 8,
  },
  {
    name: 'solar thermal',
    title: 'Solar Thermal',
    displayed: false,
    key: 9,
  },
  {
    name: 'wind',
    title: 'Wind',
    displayed: false,
    key: 10,
  },

];

const REGIONS = [
  { name: 'EastofEngland', title: 'East of England' },
  { name: 'WestMidlands', title: 'West Midlands' },
  { name: 'EastMidlands', title: 'East Midlands' },
  { name: 'SouthWest', title: 'South West' },
  { name: 'NorthWest', title: 'North West' },
  { name: 'SouthEast', title: 'South East' },
  { name: 'NorthEast', title: 'North East' },
  { name: 'SouthScotland', title: 'South Scotland' },
  { name: 'Wales', title: 'Wales' },
  { name: 'North Scotland', title: 'North Scotland' },
];

const INVITATION_COLORS_STYLES = 'bg-[#CFF5F5] text-[#048583]';
const OPPORTUNITY_COLORS_STYLES = 'bg-[#E2FFEF] text-[#50CD89]';
const BID_IN_REVIEW_COLORS_STYLES = 'bg-[#E3E7FD] text-[#415BE3]';
const SCHEDULE_VISIT_COLORS_STYLES = 'bg-[#F3E3FD] text-[#BE5CFB]';
const CONFIRMED_VISIT_COLORS_STYLES = 'bg-[#FDF2E3] text-[#E79629]';
const PROJECT_STARTED = 'bg-[#CFF5F5] text-[#048583]';

const BID_TYPES = {
  invitation: {
    name: 'Invitation',
    styles: INVITATION_COLORS_STYLES,
  },
  opportunity: {
    name: 'Opportunities',
    styles: OPPORTUNITY_COLORS_STYLES,
  },
  bidInReview: {
    name: 'Bid in Review',
    styles: BID_IN_REVIEW_COLORS_STYLES,
  },
  scheduleVisit: {
    name: 'Schedule Site Visit',
    styles: SCHEDULE_VISIT_COLORS_STYLES,
  },
  confirmedVisit: {
    name: 'Confirmed Site Visit',
    styles: CONFIRMED_VISIT_COLORS_STYLES,
  },
  projectStarted: {
    name: 'Project Started',
    styles: PROJECT_STARTED,
  },
};

const DASHBOARD_GET_STARTED = [
  {
    id: 1,
    title: 'Invitations',
    count: '03',
    btnText: 'Submit Initial Bid',
    color: '#048583',
    image: DarkGreen,
    className: 'invitations text-[#048583] group-hover:text-white',
    route: '/dashboard/invitations',
  },
  {
    id: 2,
    title: 'Opportunities',
    count: '12',
    btnText: 'Submit Initial Bid',
    color: '#50CD89',
    image: LightGreen,
    className: 'opportunities text-[#50CD89] group-hover:text-white',
    route: '/dashboard/opportunities',
  },
];

const DASHBOARD_BID_IN_PROCESS = [
  {
    id: 3,
    title: 'Bid In Review',
    count: '07',
    btnText: 'See Details',
    color: '#415BE3',
    image: Blue,
    className: 'bid-in-review text-[#415BE3] group-hover:text-white',
    route: '/installer/bidInReview',
  },
  {
    id: 4,
    title: 'Schedule Site Visit',
    count: '07',
    btnText: 'Choose Site Time Visit',
    color: '#BE5CFB',
    image: Purple,
    className: 'schedule-site-visit text-[#BE5CFB] group-hover:text-white',
    route: '/dashboard/scheduleSiteVisit',
  },
  {
    id: 5,
    title: 'Confirmed Site Visit',
    count: '02',
    btnText: 'Submit Final Bid',
    color: '#E79629',
    image: Orange,
    className: 'confirmed-site-visit text-[#E79629] group-hover:text-white',
    route: '/dashboard/confirmedSiteVisit',
  },
];

const CUSTOMER_DASHBOARD_GET_STARTED = [
  {
    id: 1,
    title: 'Send Invites',
    count: '03',
    btnText: 'Check Send Invites',
    color: '#048583',
    image: DarkGreen,
    className: 'invitations text-[#048583] group-hover:text-white',
    route: '/dashboard/sentInvites',
  },
  {
    id: 2,
    title: 'Bid Requests',
    count: '12',
    btnText: 'Check Bid Requests',
    color: '#50CD89',
    image: LightGreen,
    className: 'opportunities text-[#50CD89] group-hover:text-white',
    route: '/dashboard/bidRequests',
  },
];

const CUSTOMER_DASHBOARD_BID_IN_PROCESS = [
  {
    id: 3,
    title: 'Projects',
    count: '07',
    btnText: 'See Details',
    color: '#415BE3',
    image: Blue,
    className: 'bid-in-review text-[#415BE3] group-hover:text-white',
    route: '/dashboard/projects',
  },
  {
    id: 4,
    title: 'Projects Started',
    count: '07',
    btnText: 'See Details',
    color: '#BE5CFB',
    image: Purple,
    className: 'schedule-site-visit text-[#BE5CFB] group-hover:text-white',
    route: '/dashboard/projectsStarted',
  },
];

export {
  CREATE_ACCOUNT_STEPS,
  TECHNOLOGIES,
  REGIONS,
  BID_TYPES,
  DASHBOARD_GET_STARTED,
  DASHBOARD_BID_IN_PROCESS,
  CUSTOMER_DASHBOARD_GET_STARTED,
  CUSTOMER_DASHBOARD_BID_IN_PROCESS,
};
