import React, { useEffect, useState } from 'react';
import { SideBar } from './SideBar';
import { INSTALLER_ROUTER } from 'router/installerRouter';
import { NavBar } from './NavBar';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStartedProjects } from 'store/bid/bidActions';
import { Flag, Message } from 'assets/svgs/icons';
import { verifyToken } from 'store/auth/authActions';
import { PageLoader } from 'components/elements';
import { Badge } from 'antd';

function Installer() {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('user_role');

  const dispatch = useDispatch();
  const { startedProjects } = useSelector((state) => state.bid);
  const { messageCount } = useSelector((state) => state.chat);
  const { loading, tokenValid } = useSelector((state) => state.auth);
  const installerId = localStorage.getItem('installerId');
  const [projectRoutes, setProjectRoutes] = useState(INSTALLER_ROUTER);

  useEffect(() => {
    dispatch(getStartedProjects());
  }, [dispatch, installerId]);

  console.log({
    startedProjects
  })
  useEffect(() => {
    let children = startedProjects?.map((site) => ({
      key: site?._id,
      label: site?.siteId?.nameOfSite,
      path: `/dashboard/projects/${site._id}`,
    }));
    const projectRouteObj = {
      key: '4',
      icon: <Flag />,
      label: 'Projects Started',
      children: [...children],
    };
    const messageObj = {
      key: '6',
      icon: (
        <Badge
          color='#8DC63F'
          dot={messageCount > 0 && true}
          count={messageCount}
          className='relative'
        >
          <Message />
        </Badge>
      ),
      label: 'Messages',
      path: '/dashboard/messages',
    };
    const routerArray = [...INSTALLER_ROUTER];
    routerArray.splice(3, 0, projectRouteObj);
    routerArray.splice(5, 0, messageObj);

    setProjectRoutes(routerArray);
  }, [startedProjects, messageCount]);

  if (loading) {
    return <PageLoader />;
  }

  if (token && userRole === 'installer') {
    return (
      <div className='relative flex'>
        <div className='flex flex-col'>
          <SideBar routes={projectRoutes} />
        </div>
        <div className='p-2 md:px-10 md:py-5 flex flex-col w-full'>
          <NavBar />
          <div className='mt-0 bg-[white]'>
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to='/login' />;
  }
}

export { Installer };
