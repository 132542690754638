import axios from 'axios';


const production = "https://marketplace.ongen.energy"

export const axiosInstance = axios.create({
  baseURL: `${production}/api`,
  // baseURL: `/api`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
      config.headers.Accept = 'application/json';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('installerId');
      localStorage.removeItem('user_role');
    }
    return Promise.reject(error.response);
  }
);
