const { createAsyncThunk } = require("@reduxjs/toolkit");
const { axiosInstance } = require("api/axios");

export const getCustomerNotifications = createAsyncThunk(
  "notification/get",
  async (_, { rejectWithValue }) => {
    // get notification for current installer
    const id = localStorage.getItem("customerId");
    try {
      const response = await axiosInstance.get(`/customer/notifications/${id}`);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getDashboardDataCustomer = createAsyncThunk(
  "getDashboardDataCustomer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/dashboard`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);
