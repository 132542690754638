import React from "react";
import AuthLayoutImage from "assets/images/AuthLayoutImage.svg";
import Logo from "assets/svgs/Logo.svg";

const AuthLayout = ({ children }) => {
  return (
    <div className="w-screen h-screen overflow-hidden grid grid-cols-1 md:grid-cols-10">
      <div className="hidden md:col-span-4 md:flex flex-col gap-4 items-center justify-center">
        <img src={Logo} alt="Logo" />
        <p className="text-base text-darkGray font-medium">
          Welcome to Ongen Marketplace
        </p>
        <img
          src={AuthLayoutImage}
          alt="AuthLayoutImage"
          className="max-w-[70%] h-auto"
        />
      </div>
      <div
        className={`bg-background md:col-span-6 w-full flex flex-col items-center justify-center`}
      >
        {children}
      </div>
    </div>
  );
};

export { AuthLayout };
