import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import bidSlice from "./bid/bidSlice";
import sitesSlice from "./sites/sitesSlice";
import installerSlice from "./installer/installerSlice";
import invitesSlice from "./invites/invitesSlice";
import customerSlice from "./customer/customerSlice";
import chatSlice from "./chat/chatSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    bid: bidSlice,
    sites: sitesSlice,
    installer: installerSlice,
    invite: invitesSlice,
    customer: customerSlice,
    chat: chatSlice,
  },
});
