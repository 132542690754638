import React from 'react'
import ChatLayout from 'components/chat/ChatLayout'

function Chat() {
  return (
    <div>
        <ChatLayout />
    </div>
  )
}

export default Chat