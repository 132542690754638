import React, { useEffect, useState } from 'react';
import { InstallerInviteCard, ProjectDetails } from 'components/customer';
import { ProjectInfo } from 'components/macros';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRecommendedInstallersBySiteId,
  getSitesByCustomerId,
} from 'store/sites/sitesActions';
import { PageLoader } from 'components/elements';

// Images
import GridIcon from 'assets/svgs/Grid.svg';
import { CUSTOMER_DATA } from 'mock/data';

const SentInvites = () => {
  const dispatch = useDispatch();
  const { loading, installersLoading, sites, recommendedInstallers } =
    useSelector((state) => state.sites);

  const { loading: inviteLoading } = useSelector((state) => state.invite);
  const customerId = localStorage.getItem('customerId');

  const [selectedSite, setSelectedSite] = useState(sites ? sites[0] : {});

  useEffect(() => {
    dispatch(getSitesByCustomerId(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (sites.length) {
      setSelectedSite(sites ? sites[0] : {});
      dispatch(getRecommendedInstallersBySiteId(sites[0]?._id));
    }
  }, [sites, dispatch]);

  const onClickSite = (site) => {
    dispatch(getRecommendedInstallersBySiteId(site?._id));
    setSelectedSite(site);
  };

  return (
    <>
      {
        sites?.length > 0 ? (
          <div className='flex flex-col gap-2 py-4 text-darkGray2'>
            <p className='text-base font-medium'>
              {CUSTOMER_DATA.sendInvites['mainText']}
            </p>
            <p>{CUSTOMER_DATA.sendInvites['helperText']}</p>
          </div>
        ) : (
          <p>No Sites found!</p>
        )
      }

      {loading ? (
        <PageLoader />
      ) : (
        <div className='relative flex-column md:row-flex gap-8 w-full h-full py-4 md:py-8 px-1'>
          <div className='order-last md:order-first flex-column gap-8'>
            {selectedSite && <ProjectDetails showMatchCheck={false} site={selectedSite} />}
            {installersLoading ? (
              <div>
                <PageLoader />
              </div>
            ) : (
              <div className='flex flex-wrap gap-8 max-w-[720px]'>
                {recommendedInstallers?.length > 0 ? (
                  recommendedInstallers?.map((installer) => (
                    <InstallerInviteCard
                      key={installer._id}
                      installer={installer}
                      siteId={selectedSite?._id}
                      customerId={customerId}
                      technologies={installer.technologies}
                      loading={inviteLoading}
                      type='sendInvite'
                    />
                  ))
                ) : (
                  <p className='text-darkGray2 font-medium'>
                    {/* No recommended installers found! */}
                  </p>
                )}
              </div>
            )}
          </div>
          {sites?.length > 0 && <div className='flex-column max-h-[550px] overscroll-x-none w-full overflow-y-auto gap-2'>
            <div className='row-flex items-center gap-2'>
              <div className='w-10 h-10 rounded-full grid place-items-center bg-[#F9FFF1]'>
                <img src={GridIcon} alt='grid' />
              </div>
              <p className='text-sm md:text-lg text-black'>Projects</p>
            </div>
            {(
              sites?.map((site) => (
                <ProjectInfo
                  site={site}
                  selected={site?._id === selectedSite?._id}
                  onClick={() => {
                    onClickSite(site);
                  }}
                  key={site._id}
                />
              ))
            )}
          </div>
          }
        </div >
      )}
    </>
  );
};

export { SentInvites };
