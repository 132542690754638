import React, { useCallback, useEffect, useRef, useState } from "react";

const OtpInput = ({ otpCode, setOtpCode }) => {
  const otpInputRefs = useRef([]);
  const [focusedIndex, setFocusedIndex] = useState(null);

  useEffect(() => {
    otpInputRefs.current[0].focus();
  }, []);

  const handleOtpInputChange = useCallback(
    (event, index) => {
      const value = event.target.value;
      const newOtpCode = otpCode.split("");
      newOtpCode[index] = value;
      setOtpCode(newOtpCode.join(""));

      if (value && index < 3) {
        otpInputRefs.current[index + 1].focus();
      } else if (!value && index > 0) {
        otpInputRefs.current[index - 1].focus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [otpCode]
  );

  const handleOtpInputFocus = useCallback((event, index) => {
    event.target.select();
    setFocusedIndex(index);
  }, []);

  const handleOtpInputBlur = useCallback(() => {
    setFocusedIndex(null);
  }, []);

  const handleOtpInputKeyDown = useCallback((event, index) => {
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      otpInputRefs.current[index - 1].focus();
    }
  }, []);

  const otpInputs = Array(4)
    .fill()
    .map((_, index) => {
      const isFocused = index === focusedIndex;
      const isBeforeFocused = index < focusedIndex;

      return (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={otpCode[index] || ""}
          onChange={(event) => handleOtpInputChange(event, index)}
          onFocus={(event) => handleOtpInputFocus(event, index)}
          onBlur={handleOtpInputBlur}
          onKeyDown={(event) => handleOtpInputKeyDown(event, index)}
          ref={(ref) => (otpInputRefs.current[index] = ref)}
          className={`w-[40px] h-[42px] md:w-[60px] md:h-[65px] rounded-md text-center text-xl md:text-3xl font-medium shadow-sm ${
            isFocused || isBeforeFocused || otpCode.length === 4
              ? "outline-none shadow-md bg-white border-[2px] border-primary"
              : "bg-[#EEF1F5]"
          }`}
        />
      );
    });

  return (
    <div className="flex items-center justify-center gap-2">{otpInputs}</div>
  );
};

export { OtpInput };
