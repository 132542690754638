import {
  createInvite,
  getAcceptedInvitesForInstaller,
  getInvitesForCustomer,
  updateInvitationStatus,
} from "./invitesAction";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  loading: false,
  error: "",
  invites: [],
  invite: {},
};

const invitesSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInvite.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createInvite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getInvitesForCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvitesForCustomer.fulfilled, (state, action) => {
        state.invites = action.payload;
        state.loading = false;
      })
      .addCase(getInvitesForCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAcceptedInvitesForInstaller.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAcceptedInvitesForInstaller.fulfilled, (state, action) => {
        state.invites = action.payload;
        state.loading = false;
      })
      .addCase(getAcceptedInvitesForInstaller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateInvitationStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInvitationStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateInvitationStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default invitesSlice.reducer;
