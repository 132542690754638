import React, { useState } from 'react';
import { Button } from 'components/elements';

// Images
import EarthIllustration from 'assets/svgs/Earth2.svg';
import BoxSearchIcon from 'assets/svgs/BoxSearch.svg';
import { Alert } from 'antd';
import { useDispatch } from 'react-redux';
import { getBidsBySiteIdInCustomer, updateBid } from 'store/bid/bidActions';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const TIME = {
  morning: '08:00am - 12:00pm',
  evening: '01:00pm - 05:00pm',
};
const SiteVisitDetails = ({ bid }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [error, setError] = useState('');

  const onClickSubmitConfirmSite = () => {
    if (!value) {
      setError('Please select a time slot!');
    } else {
      setError(null);
      const payload = {
        id: bid?._id,
        data: {
          status: 'scheduledConfirmed',
          confirmedSiteVisitTime: value,
        },
      };
      dispatch(updateBid(payload))
        .unwrap()
        .then((res) => {
          toast.success('Bid updated successfully!', {
            toastId: 'scheduleCustomer',
          });
          if (id) {
            dispatch(getBidsBySiteIdInCustomer(id));
          }
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong', {
            toastId: 'scheduleCustomerError',
          });
        });
    }
  };

  return (
    <div className='relative max-w-[720px] bg-white flex-column lg:row-flex gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md'>
      <div className='flex-1 flex-column gap-2 md:gap-4'>
        <div className='row-flex items-center gap-1 md:gap-2'>
          <img
            src={BoxSearchIcon}
            alt='doc'
            className='w-[0.9rem] h-[1rem] md:w-[22px] md:h-[24px]'
          />
          <p className='text-black text-base lg:text-lg capitalize font-[400]'>
            Site Visit Details
          </p>
        </div>
        <p className='text-xs md:text-sm text-gray font-[400]'>
          You can select one of the available dates, so that the installer can
          visit your site. If neither of the dates are suitable, then use the
          messaging function to contact the installer to pick a new date/time
        </p>
        <div className='flex-column gap-2 lg:ml-7 mt-4'>
          <p className='text-gray text-xs md:text-[15px] font-[400]'>
            {bid?.confirmedSiteVisitTime
              ? 'Available Dates'
              : 'Confirmed Visit Date'}
          </p>
          {error ? (
            <div className='flex items-center w-full'>
              <Alert message={error} type='error' showIcon />
            </div>
          ) : null}
          {bid?.confirmedSiteVisitTime ? (
            <div className='flex-column gap-1'>
              <p className='text-[#5E6278] text-xs md:text-base font-[500]'>
                {new Date(bid?.confirmedSiteVisitTime?.date).toDateString()}
              </p>
              <p className='text-gray text-xs md:text-[15px] font-[400]'>
                {TIME[bid?.confirmedSiteVisitTime?.time]}
              </p>
            </div>
          ) : (
            <>
              <AvailableDates
                date={
                  new Date(bid?.siteVisitOne?.date).toDateString() ||
                  '10 MArch, 2023'
                }
                time={TIME[bid?.siteVisitOne?.time]}
                timeVal={bid?.siteVisitOne?.time}
                value={{
                  date: new Date(bid?.siteVisitOne?.date),
                  time: bid?.siteVisitOne?.time,
                }}
                setValue={setValue}
              />
              <AvailableDates
                date={
                  new Date(bid?.siteVisitTwo?.date).toDateString() ||
                  '10 MArch, 2023'
                }
                time={TIME[bid?.siteVisitTwo?.time]}
                timeVal={bid?.siteVisitTwo?.time}
                value={{
                  date: new Date(bid?.siteVisitTwo?.date),
                  time: bid?.siteVisitTwo?.time,
                }}
                setValue={setValue}
              />
            </>
          )}
        </div>
      </div>
      <div className='flex-column'>
        <img
          src={EarthIllustration}
          alt='Earth'
          className='hidden lg:block m-auto'
        />
        {bid?.status === 'scheduled' ? (
          <Button
            text='Confirm Site Visit'
            variant='bordered'
            className='max-w-[186px] mt-auto md:ml-auto'
            onClick={onClickSubmitConfirmSite}
          />
        ) : null}
      </div>
      <div className='hidden xl:block absolute top-0 left-[34px] z-[0] w-[1px] h-4 md:h-6 xl:h-8 bg-primary'>
        <div className='absolute top-0 left-1/2 transform -translate-x-1/2'></div>
      </div>
    </div>
  );
};

const AvailableDates = ({ date, time, timeVal, setValue, value }) => (
  <div className='flex-column gap-1'>
    <label className='row-flex items-center gap-2'>
      <input
        type='radio'
        name='time'
        id='time'
        onChange={() => setValue(value)}
        className='h-4 w-4 appearance-none rounded-full border-2 border-[#8A8A8A] bg-white checked:bg-primary checked:border-[#8A8A8A] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2'
      />
      <label
        htmlFor='time'
        className='text-[#5E6278] text-xs md:text-base font-[500]'
      >
        {date}
      </label>
    </label>
    <p className='text-gray text-xs md:text-[15px] font-[400] ml-8 capitalize'>
      {timeVal} ({time})
    </p>
  </div>
);

export { SiteVisitDetails };
