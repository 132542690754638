import React from 'react';

const Loader = () => {
  return <span className='loader'></span>;
};

const PageLoader = () => {
  return (
    <div className='w-full h-[90vh] m-auto flex items-center justify-center'>
      <div className='page-loader'>
        <div className='bar1'></div>
        <div className='bar2'></div>
        <div className='bar3'></div>
        <div className='bar4'></div>
        <div className='bar5'></div>
        <div className='bar6'></div>
        <div className='bar7'></div>
        <div className='bar8'></div>
        <div className='bar9'></div>
        <div className='bar10'></div>
        <div className='bar11'></div>
        <div className='bar12'></div>
      </div>
    </div>
  );
};

export { Loader, PageLoader };
