import React, { useEffect, useState } from 'react';
import { SideBar } from './SideBar';
import { NavBar } from './NavBar';
import { Navigate, Outlet } from 'react-router-dom';
import { CUSTOMER_ROUTER } from 'router/customerRouter';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomerByIdShortListed,
  getSitesByCustomerId,
} from 'store/sites/sitesActions';
import { File, Flag, Message } from 'assets/svgs/icons';
import { getStartedProjects } from 'store/bid/bidActions';
import { Badge } from 'antd';

function Customer() {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('user_role');
  const dispatch = useDispatch();
  const { shortListedSites: sites } = useSelector((state) => state.sites);
  const { startedProjects } = useSelector((state) => state.bid);
  const { messageCount } = useSelector((state) => state.chat);
  const customerId = localStorage.getItem('customerId');

  const [projectRoutes, setProjectRoutes] = useState(CUSTOMER_ROUTER);

  useEffect(() => {
    dispatch(
      getCustomerByIdShortListed({ id: customerId, isShortListed: true })
    );
    dispatch(getStartedProjects());
  }, [dispatch, customerId]);

  useEffect(() => {
    let children = sites?.map((site) => ({
      key: site?._id,
      label: site?.nameOfSite,
      path: `/dashboard/customer/projects/${site._id}`,
    }));
    const projectRouteObj = {
      key: '3',
      icon: <File />,
      label: 'Projects Pending',
      children: [...children],
    };
    let children2 = startedProjects?.map((site) => ({
      key: site?._id,
      label: site?.siteId?.nameOfSite,
      path: `/dashboard/customer/projectsStarted/${site._id}`,
    }));
    const projectRouteObj2 = {
      key: '4',
      icon: <Flag />,
      label: 'Projects Started',
      children: [...children2],
    };
    const messageObj = {
      key: '6',
      icon: (
        <Badge
          color='#8DC63F'
          dot={messageCount > 0 && true}
          count={messageCount}
          className='relative'
        >
          <Message />
        </Badge>
      ),
      label: 'Messages',
      path: '/messages',
    };
    const routerArray = [...CUSTOMER_ROUTER];
    routerArray.splice(2, 0, projectRouteObj, projectRouteObj2);
    routerArray.splice(6, 0, messageObj);

    setProjectRoutes(routerArray);
  }, [sites, startedProjects, messageCount]);

  if (token && userRole === 'customer') {
    return (
      <div className='relative flex'>
        <div className='flex flex-col'>
          <SideBar routes={projectRoutes} />
        </div>
        <div className='p-2 md:px-10 md:py-5 flex flex-col w-full'>
          <NavBar />
          <div className='mt-0 bg-[white]'>
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to='/customer/login' />;
  }
}

export { Customer };
