import { Button, Input, Modal } from 'components/elements';
import React from 'react';
import DropzoneIcon from 'assets/svgs/DropzoneIcon.svg';
import { Col, Row, Tooltip, Upload, Sc } from 'antd';
import InfoIcon from 'assets/svgs/Info.svg';

const { Dragger } = Upload;

const ToolTipContent = (
  <div className='flex flex-col gap-2'>
    <p className='text-xs font-bold text-black'>Guidelines for Final Bid</p>
    <p className='text-xs font-[400] leading-4 text-[#667085]'>
      The material cost should include the total cost for the materials that
      will be required for installation for this project.
    </p>
    <p className='text-xs font-[400] leading-4 text-[#667085]'>
      The labour cost will be the total cost for the manual installation.
    </p>
    <p className='text-xs font-[400] leading-4 text-[#667085]'>
      A quote breakdown can be attached as a file.
    </p>
  </div>
);

const FinalQuotePopup = ({
  open,
  handleClose,
  control,
  onSubmit,
  handleSubmit,
  loading,
  errors,
  fileList,
  setFileList,
  watch,
}) => {
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <Modal open={open} handleClose={handleClose} className=''>
      <div className='flex-column gap-2 1420:gap-8   w-full max-w-[280px] sm:max-w-full sm:w-[400px] h-full max-h-[70vh] overflow-auto'>
        <div className='flex flex-col gap-1'>
          <div className='flex flex-row items-center gap-1'>
            <p className='text-black text-lg lg:text-2xl font-[600]'>
              Final Quote
            </p>
            <Tooltip placement='right' title={ToolTipContent} color='white'>
              <button>
                <img src={InfoIcon} alt='' className='h-5 w-5' />
              </button>
            </Tooltip>
          </div>
          <p className='text-mediumGray text-xs lg:text-sm font-[400]'>
            Please submit your initial bid if you are interested in installing
            this project.
          </p>
        </div>
        {/* <p className='text-black text-lg lg:text-2xl font-[600]'>Final Quote</p> */}
        <Input
          type='number'
          name='materialCost'
          label='Material Cost (£)'
          control={control}
          error={errors?.materialCost ? errors.materialCost.message : ''}
        />
        <Input
          type='number'
          name='laborCost'
          label='Labour Cost (£)'
          control={control}
          error={errors?.laborCost ? errors.laborCost.message : ''}
        />
        <Input
          type='number'
          name='additionalCost'
          label='Access Cost (£)'
          control={control}
          error={errors?.additionalCost ? errors.additionalCost.message : ''}
        />
        <Input
          type='number'
          name='totalCost'
          label='Total Cost (£)'
          control={control}
          value={
            Number(watch('materialCost')) +
            Number(watch('laborCost')) +
            Number(watch('additionalCost'))
          }
          disabled
        />
        <div className={`flex flex-col items-start md:gap-1 w-full`}>
          <label className={`text-xs xl:text-sm capitalize font-medium`}>
            Attachments
          </label>
          <Row gutter={16} style={{ width: '100%' }}>
            <Col span={24}>
              <Dragger
                {...props}
                className='flex-column items-start justify-center gap-2 min-h-[150px] mb-2'
                name='attachments'
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '2px dashed #8DC63F',
                  position: 'relative',
                }}
              >
                <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
                  <img
                    src={DropzoneIcon}
                    alt='icon'
                    className='h-[24px] w-[22px] m-auto'
                  />
                  <p className='py-2'>
                    <span className='text-primary'>Click to upload</span> or
                    drag and drop
                  </p>
                </div>
              </Dragger>
            </Col>
          </Row>
        </div>
        <Button
          type='submit'
          text='Submit Final Quote'
          loading={loading}
          className='!px-4 !py-4'
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </Modal>
  );
};

export { FinalQuotePopup };
