import React from 'react';

// Images
import LocationIcon from 'assets/svgs/Location.svg';
import DocumentTextIcon from 'assets/svgs/DocumentText.svg';

const ProjectDetails = ({ installerId, site, showMatchCheck }) => {

	const getClassName = (isMatch) => `text-xs bg-${isMatch ? 'secondary text-primary' : 'lightGray text-white'} rounded-full py-1 px-1 font-[500]`;
	return (
		<div className='relative lg:min-w-[720px] max-w-[720px] h-fit bg-white flex-column gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md'>
			<div className='flex-column md:row-flex md:items-center justify-between gap-2 md:gap-4 lg:gap-8'>
				<div className='row-flex items-center gap-1 md:gap-2'>
					<img
						src={DocumentTextIcon}
						alt='doc'
						className='w-[0.9rem] h-[1rem] md:w-[22px] md:h-[24px]'
					/>
					<p className='text-[#344054] text-base lg:text-lg capitalize font-[400]'>
						Project Details
					</p>
				</div>
				<div className='row-flex gap-1'>
					<img
						src={LocationIcon}
						alt='location'
						className='w-[0.9rem] h-[1rem] md:w-[1.1rem] md:h-[1.2rem]'
					/>
					<p className='text-xs md:text-sm text-darkGray'>
						{site?.addressOfSite
							? site?.addressOfSite
							: '123 Apartment, Floor 1 ABC 123 123 Apartment.'}
					</p>
				</div>
			</div>
			<div className='flex-column gap-1'>
				<p className='text-sm lg:text-[15px] text-[#98A2B3] font-[400]'>
					Name of Site
				</p>
				<p className='text-sm md:text-base text-[#5E6278] font-[500]'>
					{site?.siteName
						? site?.siteName
						: site?.nameOfSite
							? site?.nameOfSite
							: 'Site name here'}
				</p>
			</div>
			{
				installerId && installerId?.name && (
					<div>
						<p className='text-sm lg:text-[15px] text-[#98A2B3] font-[400]'>
							Name of Installer
						</p>
						<p className='text-sm md:text-base text-[#5E6278] font-[500]'>
							{installerId?.name}
						</p>

					</div>
				)
			}
			<div className='flex-column gap-2'>
				<p className='text-sm lg:text-[15px] text-[#98A2B3] font-[400]'>
					Technology Type & Capacities
				</p>

				<div className='flex flex-wrap gap-x-4 gap-y-2'>
					{site?.technologies?.length > 0
						? site?.technologies?.map((item) => (
							<div className='row-flex items-center gap-2' key={item._id}>
								<p className='text-sm md:text-base
  
                text-[#5E6278] font-[500] capitalize'>
									{item?.technologyType === 'gas chp'
										? 'Gas CHP'
										: item?.technologyType === 'biomass chp'
											? 'Biomass CHP'
											: item?.technologyType}
								</p>
								<p
									className={showMatchCheck ? getClassName(item.isMatch) : 'bg-secondary text-primary  text-xs rounded-full py-1 px-1 font-[500]'}
								>
									{item?.capacity?.toFixed(1)} kW
								</p>
							</div>
						))
						: [1, 2, 3, 4].map((item) => (
							<div key={item} className='row-flex items-center gap-2'>
								<p className='text-sm md:text-base text-[#5E6278] font-[500]'>
									Solar PV Panels
								</p>
								<p className='text-xs text-primary bg-secondary rounded-full py-1 px-2 font-[500]'>
									50,000 kW
								</p>
							</div>
						))}
				</div>
				<div>
					<p className='text-[#98A2B3]'>Region</p>
					<p className='text-[#5E6278] font-[500]'>
						{site?.region}
					</p>
				</div>
			</div>
		</div>
	);
};

export { ProjectDetails };
