import React, { useEffect, useRef, useState } from 'react';
import Person from 'assets/svgs/Person2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedChatParticipant } from 'store/chat/chatSlice';
import { getChatByParticipants } from 'store/chat/chatActions';
import { useInterval } from '@uidotdev/usehooks';
import { Avatar } from './ChatHeader';

function ChatCard({ installer, customer, onModel, chatId }) {
  const [threadInfo, setThreadInfo] = useState();
  const userRole = localStorage.getItem('user_role');
  const { selectedChatParticpant } = useSelector((state) => state.chat);
  const isTrue = () => selectedChatParticpant?.chatId === threadInfo?.chatId;

  const bgColor = isTrue() ? 'bg-lightGreen' : 'bg-[white]';

  useEffect(() => {
    if (installer.name) {
      setThreadInfo({
        name:
          userRole === 'customer'
            ? installer.name
            : customer.name || 'Customer',
        image: userRole === 'customer' ? installer.profileImage : null,
        chatId: chatId,
        customer: customer.id,
        installer: installer.id,
      });
    }
  }, [installer.name, customer, onModel, chatId]);

  const dispatch = useDispatch();

  const handleThreadClick = () => {
    dispatch(setSelectedChatParticipant(threadInfo));
  };

  return (
    <>
      <div
        onClick={handleThreadClick}
        className={`min-w-[300px] max-w-[239px] cursor-pointer
        flex-column pl-[24px] pr-3 py-[30px] h-[114px] 
        rounded-md shadow-md w-full ${bgColor}`}
      >
        <div className='flex gap-6 items-center'>
          {threadInfo?.image ? (
            <img
              src={threadInfo?.image}
              alt='person'
              className='rounded-full w-[54px]'
            />
          ) : (
            <Avatar />
          )}
          <span>
            <p className='text-lg text-[#0D1C2E]'>{threadInfo?.name}</p>
          </span>
        </div>
      </div>
    </>
  );
}

export default ChatCard;
