import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  CreateAccount,
  ForgotPassword,
  ResetPassword,
  Login,
  Otp,
  OtpSuccess,
  CustomerLogin,
} from 'pages/auth';
import {
  BidInReview,
  ConfirmedSiteVisit,
  Invitations,
  Opportunities,
  ProjectsStarted,
  ScheduleSiteVisit,
  Profile,
  EditProfile,
  Notifications,
  Dashboard as InstallerDashboard,
} from 'pages/installer';
import { Customer, Installer } from 'components/layout';
import {
  BidRequests,
  CustomerProjectsStarted,
  Projects,
  SentInvites,
  Dashboard,
} from 'pages/customer';

import Chat from 'pages/chat/Chat';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Installer />}>
        <Route path='/dashboard' element={<InstallerDashboard />} />
        <Route path='/dashboard/invitations' element={<Invitations />} />
        <Route path='/dashboard/opportunities' element={<Opportunities />} />
        <Route path='/dashboard/bidInReview' element={<BidInReview />} />
        <Route
          path='/dashboard/scheduleSiteVisit'
          element={<ScheduleSiteVisit />}
        />
        <Route path='/dashboard/profile' element={<Profile />} />
        <Route path='/dashboard/profile/edit' element={<EditProfile />} />
        <Route
          path='/dashboard/confirmedSiteVisit'
          element={<ConfirmedSiteVisit />}
        />
        <Route path='/dashboard/projects/:id' element={<ProjectsStarted />} />
        <Route
          path='/dashboard/messages'
          element={
            <div className='text-primary items-center'>
              <Chat />
            </div>
          }
        />
        <Route
          path='/dashboard/support'
          element={<div className='text-primary items-center'>Support</div>}
        />
        <Route
          path='/dashboard/settings'
          element={<div className='text-primary items-center'>Settings</div>}
        />
        <Route path='/dashboard/notifications' element={<Notifications />} />
      </Route>
      <Route element={<Customer />}>
        <Route path='/dashboard/customer' element={<Dashboard />} />
        <Route path='/dashboard/sentInvites' element={<SentInvites />} />
        <Route path='/dashboard/bidRequests' element={<BidRequests />} />
        {/* <Route path="/customer/projects" element={<Projects />} /> */}
        <Route path='/dashboard/customer/projects/:id' element={<Projects />} />
        <Route path='/installer-profile/:id' element={<Profile />} />

        <Route
          path='/dashboard/customer/projectsStarted/:id'
          element={<CustomerProjectsStarted />}
        />

        <Route path='/messages' element={<Chat />} />
        <Route path='/notifications' element={<Notifications />} />
      </Route>
      <Route>
        <Route index path='/' element={<Navigate to='/login' />} />
        <Route index path='/create-account' element={<CreateAccount />} />
        <Route path='/login' element={<Login />} />
        <Route path='/customer/login' element={<CustomerLogin />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/verify-otp' element={<Otp />} />
        <Route path='/otp-success' element={<OtpSuccess />} />
      </Route>
    </>
  )
);

export default router;
