import React, { useEffect } from 'react';
import { DashboardCard } from 'components/macros';
// import { DASHBOARD_BID_IN_PROCESS, DASHBOARD_GET_STARTED } from "mock";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData } from 'store/installer/installerActions';
import { PageLoader } from 'components/elements';

// Images
import DarkGreen from 'assets/svgs/dashboard/DarkGreen.svg';
import LightGreen from 'assets/svgs/dashboard/LightGreen.svg';
import Purple from 'assets/svgs/dashboard/Purple.svg';
import Blue from 'assets/svgs/dashboard/Blue.svg';
import Orange from 'assets/svgs/dashboard/Orange.svg';
import { verifyToken } from 'store/auth/authActions';
import { INSTALLER_DATA } from 'mock/data';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, dashboardData } = useSelector((state) => state.installer);

  useEffect(() => {
    dispatch(getDashboardData());
  }, [dispatch]);

  const DASHBOARD_GET_STARTED = [
    {
      id: 1,
      title: 'Invitations',
      count: dashboardData?.invitations,
      btnText: 'Submit Initial Bid',
      color: '#048583',
      image: DarkGreen,
      className: 'invitations text-[#048583] group-hover:text-white',
      route: '/dashboard/invitations',
    },
    {
      id: 2,
      title: 'Opportunities',
      count: dashboardData?.opportunities,
      btnText: 'Request to Bid',
      color: '#50CD89',
      image: LightGreen,
      className: 'opportunities text-[#50CD89] group-hover:text-white',
      route: '/dashboard/opportunities',
    },
  ];

  const DASHBOARD_BID_IN_PROCESS = [
    {
      id: 3,
      title: 'Bid In Review',
      count: dashboardData?.bidInReview,
      btnText: 'See Details',
      color: '#415BE3',
      image: Blue,
      className: 'bid-in-review text-[#415BE3] group-hover:text-white',
      route: '/dashboard/bidInReview',
    },
    {
      id: 4,
      title: 'Schedule Site Visit',
      count: dashboardData?.scheduleSiteVisit,
      btnText: 'Choose Site Time Visit',
      color: '#BE5CFB',
      image: Purple,
      className: 'schedule-site-visit text-[#BE5CFB] group-hover:text-white',
      route: '/dashboard/scheduleSiteVisit',
    },
    {
      id: 5,
      title: 'Confirmed Site Visit',
      count: dashboardData?.confirmedSiteVisit,
      btnText: 'Submit Final Bid',
      color: '#E79629',
      image: Orange,
      className: 'confirmed-site-visit text-[#E79629] group-hover:text-white',
      route: '/dashboard/confirmedSiteVisit',
    },
  ];

  return loading ? (
    <div className='w-full h-[90vh] m-auto flex items-center justify-center'>
      <PageLoader />
    </div>
  ) : (
    <div className='flex-column gap-8 py-8'>
      <div className='flex-column gap-4'>
        <div className='flex flex-col gap-2 pb-4 text-darkGray2'>
          <p className='text-base font-medium'>
            {INSTALLER_DATA.dashboard['mainText']}
          </p>
          <ul className='list-disc ml-8'>
            {INSTALLER_DATA.dashboard['points'].map((item, i) => (
              <li key={'points' + 1}>
                {item.text}{' '}
                <span>
                  <Link to={item.href} className='text-primary no-underline'>
                    ({item.linkText})
                  </Link>
                </span>
              </li>
            ))}
          </ul>
          <p>{INSTALLER_DATA.dashboard['helperText']}</p>
        </div>
        <p className='text-lg font-[600] text-black '>Get Started</p>
        <div className='flex flex-wrap gap-8'>
          {DASHBOARD_GET_STARTED.map((item) => (
            <DashboardCard
              key={item.id}
              title={item.title}
              color={item.color}
              count={item.count}
              image={item.image}
              btnText={item.btnText}
              className={item?.className}
              onClick={() => navigate(item.route)}
            />
          ))}
        </div>
      </div>
      <div className='flex-column gap-4'>
        <p className='text-lg font-[600] text-black'>Bid In Process</p>
        <div className='flex flex-wrap gap-8'>
          {DASHBOARD_BID_IN_PROCESS.map((item) => (
            <DashboardCard
              key={item.id}
              title={item.title}
              color={item.color}
              count={item.count}
              image={item.image}
              btnText={item.btnText}
              className={item?.className}
              onClick={() => navigate(item.route)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { Dashboard };
