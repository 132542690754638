import React from "react";
import Logo from "assets/svgs/Logo.svg";
import HalfCircle from "assets/svgs/HalfCircle.svg";
import { Button } from "components/elements";
import { ANIMATE_RIGHT } from "utils/animations";
import { useNavigate } from "react-router-dom";

const OtpSuccess = () => {
  const navigate = useNavigate();

  const navigateToPath = (path) => {
    navigate(path);
  };

  const onClickSignIn = () => {
    sessionStorage.removeItem("email");
    navigateToPath("/login");
  };
  return (
    <div>
      <div className="relative  overflow-hidden flex-column items-center  px-4 py-4 md:py-8 gap-8 md:gap-15 1420:gap-18">
        <img src={Logo} alt="Logo" />
        <div>
          <p
            className={`text-center text-xl md:text-[1.5rem] font-[600] ${ANIMATE_RIGHT}`}
          >
            Passwword Changed successfully
          </p>
          <p className="text-center text-sm md:text-lg text-darkGray">
            Your password is successfully changed. Please Sign in to your
            account
          </p>
        </div>
        <Button
          text="Sign In"
          onClick={onClickSignIn}
          className={ANIMATE_RIGHT}
        />
      </div>
      <img
        src={HalfCircle}
        alt="banner"
        className=" lg:block max-h-[250px] 1420:max-h-full absolute bottom-0 left-0 right-0 m-auto"
      />
    </div>
  );
};

export { OtpSuccess };
