import React from 'react';
import Person from 'assets/svgs/Person.svg';
import {
  formatFileSize,
  getTimeDifference,
  isImage,
  getDisplayedFileName,
  filterFilesByExtension,
} from 'utils';
import File from 'assets/svgs/File.svg';
import FileBubble from './FileBubble';

const imageFileExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'tiff',
  'tif',
  'svg',
];

const displayGrid = (array) => {
  const gridClass =
    array.length > 3
      ? 'grid grid-cols-1 md:grid-cols-3'
      : `grid  grid-cols-1 md:grid-cols-${array.length}`;
  return gridClass;
};

const handleDownload = (event, downloadHref) => {
  event.preventDefault();
  window.location.href = downloadHref;
};

const SendBubble = ({ text, createdAt, files }) => {
  const { imageFiles, otherFiles } = filterFilesByExtension(
    files,
    imageFileExtensions
  );

  const shouldDisplayBubble = text.length > 0 && otherFiles.length > 0;
  return shouldDisplayBubble ? (
    <div className='flex-column max-w-[70%] ml-auto'>
      <div className='bg-white border-2  px-[22px] py-[11px] shadow-light ml-auto min-w-[172px]  overflow-hidden border-gray-100 rounded-tl-xl rounded-tr-xl rounded-bl-xl'>
        <div className='flex-column'>
          {text.length > 0 && (
            <p className='text-[#7E8082] break-all'>{text}</p>
          )}
          <div className='bg-white flex-column items-end gap-1 ml-auto'>
            {otherFiles.length >= 1 &&
              otherFiles.map(({ fileName, downloadHref, fileSize }) => {
                return (
                  <a
                    className='flex cursor-pointer gap-1'
                    onClick={(e) => handleDownload(e, downloadHref)}
                    target='_blank'
                    download={fileName}
                  >
                    <span>({formatFileSize(fileSize)})</span>
                    <span className='text-primary'>
                      {getDisplayedFileName(fileName, 20)}
                    </span>
                    <img src={File} />
                  </a>
                );
              })}
          </div>
        </div>
      </div>
      <p className='ml-auto mt-1 text-darkGray'>
        {getTimeDifference(createdAt)}
      </p>
    </div>
  ) : otherFiles.length === 1 && text.length === 0 ? (
    <div className='max-w-[70%] ml-auto'>
      <FileBubble {...otherFiles[0]} type='send' />
      <p className='text-right mt-1 text-darkGray'>
        {getTimeDifference(createdAt)}
      </p>
    </div>
  ) : (
    <div className='flex-column max-w-[70%] ml-auto'>
      {text.length > 0 && (
        <div className='bg-white border-2  px-[22px] border-primary py-[11px] shadow-light ml-auto min-w-[172px]  overflow-hidden border-gray-100 rounded-tl-xl rounded-tr-xl rounded-bl-xl'>
          <p className='text-[#7E8082] break-all'>{text}</p>
        </div>
      )}
      <div className={`${displayGrid(files)} ml-auto gap-4 mt-2`}>
        {imageFiles.length > 0 &&
          imageFiles.map((current, index) => {
            if (isImage(current.downloadHref)) {
              return (
                <img
                  key={index}
                  className='w-[172px] h-[172px] object-fit rounded-lg bg-primary'
                  src={current.downloadHref}
                />
              );
            }
          })}
      </div>
      <p className='ml-auto mt-1 text-darkGray'>
        {getTimeDifference(createdAt)}
      </p>
    </div>
  );
};

const ReceiveBubble = ({ text, createdAt, files }) => {
  const { imageFiles, otherFiles } = filterFilesByExtension(
    files,
    imageFileExtensions
  );
  const shouldDisplayBubble = text.length > 0 && otherFiles.length > 0;

  return shouldDisplayBubble ? (
    <div className='flex-column max-w-[70%] mr-auto'>
      <div className='bg-lightGreen px-[22px] py-[11px] shadow-bottom rounded-tr-xl  rounded-br-xl rounded-bl-xl max-w-[372px]'>
        <div className='flex-column'>
          {text.length > 0 && (
            <p className='text-[#7E8082] py-4 w-[90%] break-all'>{text}</p>
          )}
          <div className='flex-column gap-1'>
            {otherFiles.length >= 1 &&
              otherFiles.map(({ fileName, downloadHref, fileSize }) => {
                return (
                  <a
                    className='flex items-end text-primary cursor-pointer gap-1'
                    onClick={(e) => handleDownload(e, downloadHref)}
                    target='_blank'
                    download={fileName}
                  >
                    <img src={File} alt='file-icon' />
                    <span>({formatFileSize(fileSize)})</span>
                    <span>{getDisplayedFileName(fileName, 20)}</span>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
      <p className='ml-auto mt-1 text-darkGray'>
        {getTimeDifference(createdAt)}
      </p>
    </div>
  ) : otherFiles.length === 1 && text.length === 0 ? (
    <div className='max-w-[70%]'>
      <FileBubble {...otherFiles[0]} type='receive' />
      <p className='text-left mt-1 text-darkGray'>
        {getTimeDifference(createdAt)}
      </p>
    </div>
  ) : (
    <div className='flex-column max-w-[70%] mr-auto'>
      {text.length > 0 && (
        <div className='bg-lightGreen px-[22px] py-[11px] shadow-bottom rounded-tr-xl  rounded-br-xl rounded-bl-xl max-w-[372px]'>
          <p className='text-[#7E8082] break-words'>{text}</p>
        </div>
      )}
      <div className={`${displayGrid(files)} ml-auto gap-4 mt-2`}>
        {imageFiles.length > 0 &&
          imageFiles.map((current, index) => {
            if (isImage(current.downloadHref)) {
              return (
                <img
                  key={index}
                  className='w-[172px] h-[172px] object-fit rounded-lg bg-primary'
                  src={current.downloadHref}
                />
              );
            }
          })}
      </div>
      <p className='ml-auto mt-1 text-darkGray'>
        {getTimeDifference(createdAt)}
      </p>
    </div>
  );
};

export { SendBubble, ReceiveBubble };
