export const ANIMATE_TOP_LEFT =
  "animate__animated animate__fadeInTopLeft animate__faster";
export const ANIMATE_BOTTOM_RIGHT =
  "animate__animated animate__fadeInBottomRight animate__faster";
export const ANIMATE_UP = "animate__animated animate__fadeInUpBig";
export const ANIMATE_RIGHT =
  "animate__animated animate__fadeInRight animate__fast";
export const ANIMATE_IN = "animate__animated animate__fadeIn";
export const ANIMATE_IN_DOWN = "animate__animated animate__fadeInDown";

export const ANIMATE_ZOOM_IN = "animate__animated animate__zoomIn";
export const ANIMATE_SLIDEIN_UP = "animate__animated animate__slideInUp";
export const ANIMATE_SLIDEIN_DOWN =
  "animate__animated animate__slideInDown animate__faster";
export const ANIMATE_SLIDEIN_LEFT = "animate__animated animate__slideInLeft";
export const ANIMATE_SLIDEIN_RIGHT = "animate__animated animate__slideInRight";
