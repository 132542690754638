import { axiosInstance } from "api/axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

export const createBid = createAsyncThunk(
  "/bid/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/bids", payload);
      return response;
    } catch (err) {
      console.log("Error =>", err);
      return rejectWithValue(err?.data?.error?.message);
    }
  }
);

export const getBidsBySiteIdInCustomer = createAsyncThunk(
  "/bid/getBidsBySiteIdInCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/customer/${id}`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const updateBidStatus = createAsyncThunk(
  "/bid/updateBidStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/bids/status/${payload.id}`,
        payload.data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getBidsByStatus = createAsyncThunk(
  "/bid/getBidsByStatus",
  async (status, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/status/${status}`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const updateBid = createAsyncThunk(
  "/bid/updateBid",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/bids/${payload.id}`,
        payload.data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getStartedProjects = createAsyncThunk(
  "/bid/getStartedProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`bids/completed`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);
