import React, { useState } from 'react';
import { CreateAccountForm, CreateAccountSteps } from 'components/auth';
import HalfCircle from 'assets/svgs/HalfCircle.svg';
import { useNavigate } from 'react-router-dom';

function CreateAccount() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const handleNext = () => setStep(step + 1);
  const handlePrevious = () => setStep(step - 1);
  return (
    <div
      className={`relative w-screen ${
        step === 3 ? 'min-h-screen overflow-auto' : 'h-screen overflow-auto'
      }  grid grid-cols-1 md:grid-cols-10`}
    >
      <div className='md:col-span-4 md:flex flex-col gap-4  items-center justify-center'>
        <CreateAccountSteps step={step} />
        <img
          src={HalfCircle}
          alt='half-circle'
          className='hidden lg:block w-[70%] max-h-[650px]'
        />
      </div>
      <div className='relative md:col-span-6 bg-background p-8 md:p-4 1420:p-8 flex items-center justify-center'>
        <div className='w-full max-w-[600px] mx-auto flex-column items-center justify-center gap-8 md:gap-6 xl:gap-8'>
          <p className='hidden md:block absolute top-5 right-5 text-xs md:text-sm text-darkGray font-[500] ml-auto'>
            Having Issues?{' '}
            <span className='text-primary cursor-pointer'>Get Help</span>
          </p>
          <div className={`w-full flex flex-col items-center md:items-start`}>
            <p className='text-xl xl:text-3xl font-[600]'>
              Create Installer Account
            </p>
            <p className='text-xs xl:text-sm text-darkGray font-[600]'>
              Already Have an account?{' '}
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  navigate('/login');
                }}
              >
                Sign In
              </span>
            </p>
          </div>
          <div className='w-full'>
            <CreateAccountForm
              step={step}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { CreateAccount };
