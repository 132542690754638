import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { Breadcrumb } from "components/elements";

function NavBar() {
  const { sites } = useSelector((state) => state.sites);
  const { invites } = useSelector((state) => state.invite);
  const { bids } = useSelector((state) => state.bid);
  const { startedProjects } = useSelector((state) => state.bid);
  const { recommendedInstallers } = useSelector((state) => state.sites);
  const { notifications } = useSelector((state) => state.customer);

  const { notifications: installerNotifications } = useSelector(
    (state) => state.installer
  );

  const location = useLocation();
  const { pathname } = location;
  // const { id } = useParams(); // Get the ID from the URL

  const [count, setCount] = useState(0);

  const NavBarTitle = {
    '/dashboard': 'Dashboard',
    '/dashboard/customer': 'Dashboard',
    '/dashboard/bidInReview': 'Bid In Process',
    '/dashboard/scheduleSiteVisit': 'Bid In Process',
    '/dashboard/notifications': 'Notifications',
    '/dashboard/confirmedSiteVisit': 'Bid In Process',
    '/dashboard/invitations': 'Get Started',
    '/dashboard/opportunities': 'Get Started',
    '/dashboard/messages': 'Messages',
    '/dashboard/sentInvites': 'Get Started',
    '/dashboard/bidRequests': 'Get Started',
    '/notifications': 'Notifications',
    '/messages': 'Messages',
    '/dashboard/customer/projectsStarted': 'Project Started',
  };

  const notShowCount = [
    '/dashboard',
    '/dashboard/profile',
    '/dashboard/customer',
    '/dashboard/profile/edit',
    '/installer-profile/',
    '/dashboard/messages',
    '/messages',
  ];

  // Add the ID to the NavBarTitle for "customer/projects"

  useEffect(() => {
    if (notShowCount.includes(pathname)) {
      setCount('');
    } else if (pathname === '/dashboard/opportunities') {
      setCount(sites.length);
    } else if (pathname === '/dashboard/invitations') {
      setCount(invites.length);
    } else if (
      pathname === '/dashboard/bidInReview' ||
      pathname === '/dashboard/confirmedSiteVisit' ||
      pathname === '/dashboard/scheduleSiteVisit'
    ) {
      setCount(bids.length);
    } else if (pathname === '/dashboard/sentInvites') {
      setCount(recommendedInstallers.length);
    } else if (pathname === '/dashboard/bidRequests') {
      setCount(invites.length);
    } else if (
      pathname.includes('/dashboard/projects') ||
      pathname.includes('/dashboard/customer/projects')
    ) {
      setCount(bids.length);
    } else if (
      pathname.includes('/dashboard/projects') ||
      pathname.includes('/dashboard/projectsStarted/')
    ) {
      setCount(startedProjects.length);
    } else if (
      pathname.includes('/dashboard/notifications') ||
      pathname.includes('/notifications')
    ) {
      setCount(notifications.length);
    } else if (pathname.includes('/dashboard/notifications')) {
      setCount(installerNotifications.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    sites.length,
    invites.length,
    bids.length,
    startedProjects.length,
    notifications.length,
    installerNotifications.length,
    recommendedInstallers.length,
  ]);

  return (
    <div className='flex-column justify-between w-full gap-4'>
      {/* <Breadcrumb /> */}
      <span>
        <p className='text-xl font-medium'>
          <>
            {location.pathname.includes('/installer-profile/')
              ? 'Profile'
              : location.pathname.includes('/dashboard/projects/') ? 'Projects Started' :
                location.pathname.includes('/dashboard/customer/projects/')
                  ? 'Projects Pending'
                  : location.pathname.includes('/customer/projectsStarted')
                    ? 'Project Started'
                    : NavBarTitle[location.pathname]}

            {!location.pathname.includes('/installer-profile/') ||
              (!location.pathname.includes('/customer/projectsStarted') && (
                <span className='text-[16px] ml-1 text-gray-400'>{count}</span>
              ))}
          </>
        </p>
        <div className='mt-[5px] border-yellow-400 rounded-md border-2' />
      </span>
    </div>
  );
}

export { NavBar };
