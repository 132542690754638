import React, { useEffect, useState } from 'react';
import ArrowUp from 'assets/svgs/ArrowUp.svg';
import ArrowDown from 'assets/svgs/ArrowDown.svg';
import { Dot } from 'assets/svgs/icons/Dot';
import { Link, useLocation } from 'react-router-dom';
import { Popover } from 'antd';
import { useWindowDimesions } from 'hooks';

function SideBarItem({ item, collapsed }) {
  const { width } = useWindowDimesions();
  const [isArrowUp, setIsArrowUp] = useState(false);
  const location = useLocation();
  const SHOULD_HOVER = isArrowUp || !item?.children ? 'hover:bg-secondary' : '';
  const isActive = item.path === location.pathname;

  function isChildActive(childPath) {
    return location.pathname.startsWith(childPath);
  }

  const BG_COLOR =
    isArrowUp && item?.children?.length > 0
      ? 'bg-lightGray2'
      : isActive
      ? 'bg-lightGray2'
      : 'bg-white';

  const handleClick = (path) => {
    setIsArrowUp(!isArrowUp);
  };
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    if (width > 1200) {
      setPopupVisible(false);
    }
  }, [width]);

  const handleClickOnMobile = () => {
    setPopupVisible(!popupVisible);
  };

  return (
    <div className={`flex-column text-[#344054] ${BG_COLOR} w-full rounded-md`}>
      <div
        onClick={handleClick}
        className={`hidden xl:block cursor-pointer py-[10px] px-[16px] rounded-md text-left w-full ${SHOULD_HOVER}`}
      >
        <Link to={item?.path}>
          <span className='flex flex-col gap-4'>
            <span className='flex justify-between items-center'>
              <span className='flex items-center gap-[15px] font-medium'>
                {item.icon}
                <p className='hidden xl:block text-sm 2xl:text-base'>
                  {item.label}
                </p>
              </span>
              {item.children && (
                <img
                  className='hidden xl:block'
                  src={isArrowUp ? ArrowUp : ArrowDown}
                  alt='icon'
                />
              )}
            </span>
          </span>
        </Link>
      </div>
      {item.children ? (
        <div
          onClick={handleClickOnMobile}
          className={`xl:hidden cursor-pointer py-[10px] px-[16px] rounded-md text-left w-full ${SHOULD_HOVER}`}
        >
          <Popover
            placement='rightTop'
            title={item.label}
            trigger='click'
            visible={popupVisible}
            content={
              <span className='flex rounded-b-md text-[15px] py-4 pl-5 flex-col text-[#8D9198] gap-[10px] w-full max-h-[10rem] overflow-auto'>
                {item?.children?.map((child) => (
                  <p
                    key={child.key}
                    className='text-xs 2xl:text-sm flex items-center gap-2 2xl:gap-[26px] cursor-pointer'
                  >
                    <Dot
                      color={isChildActive(child.path) ? '#8DC63F' : '#8D9198'}
                    />
                    <Link
                      to={child.path}
                      className={`${
                        isChildActive(child.path) ? 'text-primary' : ''
                      }`}
                    >
                      {child.label}
                    </Link>
                  </p>
                ))}
              </span>
            }
          >
            <button>{item.icon}</button>
          </Popover>
        </div>
      ) : (
        <div
          onClick={handleClickOnMobile}
          className={`xl:hidden cursor-pointer py-[10px] px-[16px] rounded-md text-left w-full ${SHOULD_HOVER}`}
        >
          <Link to={item?.path}>
            <span className='flex flex-col gap-4'>
              <span className='flex justify-between items-center'>
                <span className='flex items-center gap-[15px] font-medium'>
                  {item.icon}
                </span>
              </span>
            </span>
          </Link>
        </div>
      )}

      {item.children && isArrowUp && (
        <span className='hidden xl:flex rounded-b-md text-[15px] py-4 pl-5 flex-col text-[#8D9198] gap-[10px] w-full'>
          {item?.children?.map((child) => (
            <p
              key={child.key}
              className='text-xs 2xl:text-sm flex items-center gap-2 2xl:gap-[26px] cursor-pointer'
            >
              <Dot color={isChildActive(child.path) ? '#8DC63F' : '#8D9198'} />
              <Link
                to={child.path}
                className={`${
                  isChildActive(child.path) ? 'text-primary' : ''
                } text-left`}
              >
                {child.label}
              </Link>
            </p>
          ))}
        </span>
      )}
    </div>
  );
}

export { SideBarItem };
