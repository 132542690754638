import { AuthLayout } from 'components/auth';
import { Button, Input, PasswordInput } from 'components/elements';
import React from 'react';
import { useForm } from 'react-hook-form';
import Logo from 'assets/svgs/Logo.svg';
import { useNavigate } from 'react-router-dom';
import { ANIMATE_TOP_LEFT } from 'utils/animations';
import { useDispatch, useSelector } from 'react-redux';
import { installerLogin } from 'store/auth/authActions';
import { yupResolver } from '@hookform/resolvers/yup';
import { installerLoginSchema } from 'validations';
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(installerLoginSchema),
  });

  const navigateToPath = (path) => {
    navigate(path);
  };

  const onSubmit = async (data) => {
    dispatch(installerLogin(data))
      .unwrap()
      .then((res) => {
        toast.success('Successfully logged In!', {
          toastId: 'loggedIn',
        });
        navigate('/dashboard');
      })
      .catch((err) => {
        toast.error(err, { toastId: 'LoginError', className: 'toast-message' });
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <AuthLayout>
      <div
        className={`w-full max-w-[500px] m-auto flex flex-col items-start justify-center gap-6 md:gap-12 px-4`}
      >
        <img src={Logo} alt='Logo' className='md:hidden w-[60%] m-auto mb-6' />
        <div
          className={`w-full flex flex-col items-center md:items-start gap-2 ${ANIMATE_TOP_LEFT}`}
        >
          <p className='text-xl md:text-3xl font-[600]'>Installer Sign In</p>
          <p className='text-sm text-darkGray font-[600]'>
            New Here?{' '}
            <span
              className='text-primary cursor-pointer'
              onClick={() => navigateToPath('/create-account')}
            >
              Create Account
            </span>
          </p>
        </div>
        <Input
          name='email'
          label='Your Email'
          variant='white'
          control={control}
          error={errors.email ? errors.email.message : ''}
          onKeyDown={handleKeyDown}
        />
        <div className='w-full flex flex-col'>
          <div className='flex items-center justify-between'>
            <label className='text-xs xl:text-sm capitalize font-medium'>
              Your Password
            </label>
            <p
              className='text-xs md:text-sm text-primary cursor-pointer'
              onClick={() => navigateToPath('/forgot-password')}
            >
              Forgot Password
            </p>
          </div>
          <PasswordInput
            name='password'
            variant='white'
            control={control}
            setValue={setValue}
            error={errors.password ? errors.password.message : ''}
            onKeyDown={handleKeyDown}
          />
        </div>
        <Button
          type='submit'
          onClick={handleSubmit(onSubmit)}
          // onClick={() => toast.success("Wellos,mfmndsf ")}
          text='Sign In'
          className={`w-full ${ANIMATE_TOP_LEFT}`}
          loading={loading}
        />
      </div>
    </AuthLayout>
  );
};

export { Login };
