import { AuthLayout } from 'components/auth';
import { Button, Input, PasswordInput } from 'components/elements';
import React from 'react';
import { useForm } from 'react-hook-form';
import Logo from 'assets/svgs/Logo.svg';
import { useNavigate } from 'react-router-dom';
import { ANIMATE_TOP_LEFT } from 'utils/animations';
import { useDispatch, useSelector } from 'react-redux';
import { customerLogin } from 'store/auth/authActions';
import { yupResolver } from '@hookform/resolvers/yup';
import { installerLoginSchema } from 'validations';
import { toast } from 'react-toastify';

const CustomerLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(installerLoginSchema),
  });

  const onSubmit = async (data) => {
    dispatch(customerLogin(data))
      .unwrap()
      .then((res) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('customerId', res.customer._id);
        localStorage.setItem('user_role', res.customer.role);
        toast.success('LoggedIn Successfully!', { toastId: 'customerLogin' });
        navigate('/dashboard/customer');
      })
      .catch((err) => {
        toast.error(err, { toastId: 'customerLoginError' });
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <AuthLayout>
      <div
        className={`w-full max-w-[500px] m-auto flex flex-col items-start justify-center gap-6 md:gap-12 px-4`}
      >
        <img src={Logo} alt='Logo' className='md:hidden w-[60%] m-auto mb-6' />
        <div
          className={`w-full flex flex-col items-center md:items-start gap-2 ${ANIMATE_TOP_LEFT}`}
        >
          <p className='text-xl md:text-3xl font-[600]'>Customer Sign In</p>
        </div>
        <Input
          name='email'
          label='Your Email'
          variant='white'
          control={control}
          error={errors.email ? errors.email.message : ''}
          onKeyDown={handleKeyDown}
        />
        <PasswordInput
          name='password'
          label='Your Password'
          variant='white'
          control={control}
          setValue={setValue}
          error={errors.password ? errors.password.message : ''}
          onKeyDown={handleKeyDown}
        />
        <Button
          type='submit'
          onClick={handleSubmit(onSubmit)}
          text='Sign In'
          className={`w-full ${ANIMATE_TOP_LEFT}`}
          loading={loading}
        />
      </div>
    </AuthLayout>
  );
};

export { CustomerLogin };
