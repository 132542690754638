import { Button, Input, Modal } from 'components/elements';
import React from 'react';
import InfoIcon from 'assets/svgs/Info.svg';
import { Tooltip } from 'antd';

const ToolTipContent = (
  <div className='flex flex-col gap-2'>
    <p className='text-xs font-bold text-black'>Guidelines for Initial Bid</p>
    <p className='text-xs font-[400] leading-4 text-[#667085]'>
      The material cost should include the total cost for the materials that
      will be required for installation for this project.
    </p>
    <p className='text-xs font-[400] leading-4 text-[#667085]'>
      The labour cost will be the total cost for the manual installation.
    </p>
    <p className='text-xs font-[400] leading-4 text-[#667085]'>
      If you are invited to review the site at a site visit, then you will have
      the opportunity to refine the initial bid and provide a full cost
      breakdown.
    </p>
  </div>
);

const InitialBidPopup = ({
  open,
  handleClose,
  control,
  handleSubmit,
  onSubmit,
  loading,
  errors,
  watch,
}) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <div className='flex-column gap-4 md:gap-8 [300px] md:w-[400px] h-full max-h-[80vh] overflow-auto'>
        <div className='flex flex-col gap-1 md:gap-2'>
          <div className='flex flex-row items-center gap-1'>
            <p className='text-black text-lg lg:text-2xl font-[600]'>
              Initial Bid
            </p>
            <Tooltip placement='right' title={ToolTipContent} color='white'>
              <button>
                <img src={InfoIcon} alt='' className='h-5 w-5' />
              </button>
            </Tooltip>
          </div>
          <p className='text-mediumGray text-xs lg:text-base font-[400]'>
            Please submit your initial bid if you are interested in installing
            this project.
          </p>
        </div>
        <Input
          type='number'
          name='materialCost'
          label='Material Cost (£)'
          control={control}
          error={errors?.materialCost ? errors.materialCost.message : ''}
        />
        <Input
          type='number'
          name='laborCost'
          label='Labour Cost (£)'
          control={control}
          error={errors?.laborCost ? errors.laborCost.message : ''}
        />
        <Input
          type='number'
          name='additionalCost'
          label='Access Cost (£)'
          control={control}
          error={errors?.additionalCost ? errors.additionalCost.message : ''}
        />
        <Input
          type='number'
          name='totalCost'
          label='Total Cost (£)'
          value={
            Number(watch('materialCost')) +
            Number(watch('laborCost')) +
            Number(watch('additionalCost'))
          }
          control={control}
          disabled
          // error={errors?.totalCost ? errors.totalCost.message : ""}
        />
        {/* <Input
          name="additionalDetails"
          label="Additional Details"
          control={control}
          error={
            errors?.additionalDetails ? errors.additionalDetails.message : ""
          }
        /> */}
        <Button
          type='submit'
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          text='Submit Initial Bid'
        />
      </div>
    </Modal>
  );
};

export { InitialBidPopup };
