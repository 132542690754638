import {
  createBid,
  getBidsBySiteIdInCustomer,
  getBidsByStatus,
  getStartedProjects,
  updateBid,
  updateBidStatus,
} from "./bidActions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  loading: false,
  error: "",
  bids: [],
  startedProjects: [],
};

const bidSlice = createSlice({
  name: "bid",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createBid.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBid.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBidsBySiteIdInCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBidsBySiteIdInCustomer.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.loading = false;
      })
      .addCase(getBidsBySiteIdInCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBidStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBidStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateBidStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBidsByStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBidsByStatus.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.loading = false;
      })
      .addCase(getBidsByStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBid.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBid.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getStartedProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStartedProjects.fulfilled, (state, action) => {
        state.startedProjects = action.payload;
        state.loading = false;
      })
      .addCase(getStartedProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default bidSlice.reducer;
