import { Menu, Stack, Notification, Message } from 'assets/svgs/icons';

export const CUSTOMER_ROUTER = [
  {
    key: '1',
    icon: <Menu />,
    label: 'Dashboard',
    path: '/dashboard/customer',
  },
  {
    key: '2',
    icon: <Stack />,
    label: 'Get Started',
    children: [
      {
        key: '21',
        label: 'Send Invites',
        path: '/dashboard/sentInvites',
      },
      {
        key: '22',
        label: 'Bid Requests',
        path: '/dashboard/bidRequests',
      },
    ],
  },
  {
    key: '5',
    icon: <Notification />,
    label: 'Notifications',
    path: '/notifications',
  },
];
