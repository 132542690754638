import uploadFileToS3 from 'utils/s3Upload';
const { createAsyncThunk } = require('@reduxjs/toolkit');
const { axiosInstance } = require('api/axios');

export const getChatByParticipants = createAsyncThunk(
  '/chat/getChatById',
  async (participants, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/chat/participants`,
        participants
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const addMessageToChat = createAsyncThunk(
  'chat/addMessage',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      let attachedFiles = [];

      if (payload.files.length > 0) {
        attachedFiles = await Promise.all(
          payload.files.map(async ({ file }) => {
            const { fileName, fileSize, fileType, url } = await uploadFileToS3(
              file
            );
            return {
              fileName,
              fileSize,
              fileType,
              downloadHref: url,
            };
          })
        );
      }

      const chat = {
        ...payload,
        files: attachedFiles,
        senderModel: localStorage.getItem('user_role'),
      };

      const response = await axiosInstance.post(`/chat/${id}/message`, {
        ...chat,
      });

      return response.chat.messages;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getChatThreads = createAsyncThunk(
  '/chat/getChatThreads',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/chat/threads`);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);
