import React, { useEffect } from 'react';
import ChatCard from './ChatCard';
import GridIcon from 'assets/svgs/Grid.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getChatThreads } from 'store/chat/chatActions';

function UsersList() {
  const { chatThreads } = useSelector((state) => state.chat);
  const userRole = localStorage.getItem('user_role');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChatThreads());
  }, []);

  return (
    <div className='flex-column h-[80vh] pt-[20px] gap-4 w-full'>
      <div className='row-flex items-center gap-2'>
        <div className='w-10 h-10 rounded-full grid place-items-center bg-[#F9FFF1]'>
          <img src={GridIcon} alt='grid' />
        </div>
        <p className='text-sm md:text-lg text-black'>
          {userRole === 'installer' ? 'Customers' : 'Installers'}
        </p>
      </div>
      <div className='flex lg:flex-column w-full h-full md:max-w-[700px] gap-4 overflow-auto'>
        {chatThreads.map((current, index) => {
          return <ChatCard {...current} index={index} />;
        })}
      </div>
    </div>
  );
}

export default UsersList;
