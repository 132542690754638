import React from "react";
import { List, Button } from 'antd';
import { downloadFile } from 'utils';

function FileLists({ fileList }) {
	return (
		fileList?.length > 0 ? (
			<List
				dataSource={fileList}
				className='max-h-[130px] overflow-auto'
				renderItem={(file, index) => {
					const fileName = file?.substring(file?.lastIndexOf('/') + 1);
					return (
						<List.Item
							actions={[
								<Button size='small' onClick={() => downloadFile(file, fileName)}>
									Download
								</Button>,
							]}
						>
							<List.Item.Meta
								title={fileName}
							/>
						</List.Item>
					);
				}}
			/>
		) : null
	);
}

export default FileLists;
