import {
  addMessageToChat,
  getChatByParticipants,
  getChatThreads,
} from './chatActions';
const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  chat: [],
  chatThreads: [],
  selectedChatParticpant: {},
  loading: false,
  addMessageLoader: false,
  firstChatLoader: false,
  messageCount: 0,
};

const chatSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSelectedChatParticipant: (state, action) => {
      state.selectedChatParticpant = action.payload;
    },
    setMessageCount: (state) => {
      state.messageCount = state.messageCount + 1;
    },
    clearMessageCount: (state) => {
      state.messageCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMessageToChat.pending, (state) => {
        state.addMessageLoader = true;
      })
      .addCase(addMessageToChat.fulfilled, (state, action) => {
        state.addMessageLoader = false;
        state.chat.messages = action.payload;
      })
      .addCase(addMessageToChat.rejected, (state) => {
        state.addMessageLoader = false;
      })
      .addCase(getChatThreads.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChatThreads.fulfilled, (state, action) => {
        state.loading = false;
        state.chatThreads = action.payload;
      })
      .addCase(getChatThreads.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getChatByParticipants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChatByParticipants.fulfilled, (state, action) => {
        state.loading = false;
        state.chat = action.payload;
      })
      .addCase(getChatByParticipants.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setSelectedChatParticipant,
  setMessageCount,
  clearMessageCount,
} = chatSlice.actions;
export default chatSlice.reducer;
