import * as yup from "yup";

const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const installerLoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const installerForgetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const Step1Schema = yup.object().shape({
  name: yup.string().required("Name is required!"),
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Email is required!"),
  password: yup.string().required("Password is required!").min(8),
});

export const Step2Schema = yup.object().shape({
  companyName: yup.string().required("Company name is a required field!"),
  address: yup.string().required("Address is a required field!"),
  description: yup.string(),
  postcode: yup.string().required("Postcode is a required field!"),
  website: yup
    .string()
    .matches(re, "Website must be a valid URL beginning with https://"),
});

const profileSchema = yup.object().shape({
  email: yup.string().required("Email is required"),
  companyName: yup.string().required("Company Name is required"),
  address: yup.string().required("Address is required"),
  postcode: yup.string().required("Postcode is required"),
  website: yup.string().required("Website is required"),
  text: yup.string(),
  technologies: yup.array().of(
    yup.object().shape({
      technologyType: yup.string().required("Technology Type is required"),
      minCapacity: yup.string().required("Min. Project Capacity is required"),
      maxCapacity: yup.string().required("Max. Project Capacity is required"),
    })
  ),
  region: yup
    .array()
    .of(yup.string().required("Region is required"))
    .test("at-least-one-region", "At least one region is required", function (
      value
    ) {
      return value && value.length >= 1;
    }),
});

export { profileSchema };
