import React from 'react';
import { Controller } from 'react-hook-form';

const Input = ({
  type,
  placeholder,
  variant,
  rightIcon,
  leftIcon,
  className,
  name,
  label,
  control,
  labelType,
  divClassName = 'w-full',
  error,
  onChange,
  disabled,
  max,
  ...rest
}) => {
  function handleKeyDown(event) {
    let keyCode = event.keyCode;
    let value = event.target.value.length;

    if (keyCode === 181) {
      return false;
    }

    if ((value >= 0 && value <= 15) || keyCode === 8) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  return (
    <div className={`flex justify-between flex-col ${divClassName}`}>
      <label
        className={`text-xs xl:text-sm capitalize ${
          labelType === 'normal' ? 'font-normal' : 'font-medium'
        }`}
      >
        {label}
      </label>

      <div className='flex-column gap-2'>
        <div
          className={`w-full h-[45px] 1420:h-[55px] flex flex-row items-center justify-between
         gap-1 rounded-[8px] ${
           variant === 'white'
             ? 'bg-white border-background border-[2px]'
             : 'bg-background'
         } ${className}`}
        >
          {leftIcon ? (
            <img src={leftIcon} className='h-5 w-5' alt='icon' />
          ) : null}
          <Controller
            control={control}
            name={name}
            render={({ field }) => {
              if (type === 'number') {
                return (
                  <input
                    max={20}
                    type='number'
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`w-full px-2 h-full text-black text-sm lg:text-base 
              rounded-[8px] bg-transparent focus:outline-none  ${className}`}
                    {...field}
                    {...rest}
                  />
                );
              } else {
                return (
                  <input
                    type={type ? type : 'text'}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`w-full px-2 h-full text-black text-sm lg:text-base 
              rounded-[8px] bg-transparent focus:outline-none  ${className}`}
                    {...field}
                    {...rest}
                  />
                );
              }
            }}
          />

          {rightIcon ? (
            <img src={rightIcon} className='h-5 w-5' alt='icon' />
          ) : null}
        </div>
        {error ? (
          <p className='text-xs xl:text-sm text-red-500 -mt-1'>{error}</p>
        ) : null}
      </div>
    </div>
  );
};

export { Input };
