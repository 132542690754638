import React from 'react';
import { useSelector } from 'react-redux';
import { REGIONS } from 'mock';

function RegionsList() {
  const { installers: installer } = useSelector((state) => state.installer);

  const filteredRegions = Array.isArray(installer.region)
    ? installer.region.filter(Boolean)
    : [];

  return (
    <div className='flex flex-col gap-[17px]'>
      <div className='flex flex-column'>
        <p className='text-[#98A2B3] font-bold text-[15px]'>Supplied Regions</p>
        <p className='text-[#98A2B3] font-[400] text-[15px]'>
          What regions of the UK does your company supply to?
        </p>
      </div>
      <div className='grid-container'>
        {filteredRegions.map((name, index) => {
          const matchedRegion = REGIONS?.find(
            (region) => region.name.toLowerCase() === name.toLowerCase()
          );

          if (matchedRegion) {
            return (
              <div className='row-flex items-center gap-2' key={index}>
                <span className='bg-primary h-[8px] w-[8px] rounded-full' />
                <p className='text-sm md:text-base text-[#5E6278] font-[500] capitalize'>
                  {matchedRegion.title}
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export { RegionsList };
