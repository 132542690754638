import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, ConfigProvider, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from 'components/elements';
import { BID_TYPES } from 'mock';
import { FinalQuotePopup } from './FinalQuotePopup';
import { TimePopup } from './SetTImePopup';
import { yupResolver } from '@hookform/resolvers/yup';
import { finalBidSchema } from 'validations';
import { getBidsByStatus, updateBid } from 'store/bid/bidActions';
import { toast } from 'react-toastify';
import uploadFileToS3 from 'utils/s3Upload';

// Images
import LocationIcon from 'assets/svgs/Location.svg';
import DocumentIcon from 'assets/svgs/Document.svg';
import CalendarIcon from 'assets/svgs/Calendar.svg';
import WatchIcon from 'assets/svgs/Watch.svg';
import WatchGrayIcon from 'assets/svgs/WatchGray.svg';

const TIME = {
  morning: '08:00am - 12:00pm',
  evening: '01:00pm - 05:00pm',
};

const BidInProcessCard = ({ type, site, bidId, bid, customer }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.bid);
  const [finalQuotePopupOpen, setFinalQuotePopupOpen] = useState(false);
  const [timePopupOpen, seTimePopupOpen] = useState(false);
  const [timePopupOpen2, seTimePopupOpen2] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleFinalQuotePopupClose = () => {
    setFinalQuotePopupOpen(false);
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(finalBidSchema) });

  const [siteVisitMorning, setSiteVisitMorning] = useState();
  const [siteVisitEvening, setSiteVisitEvening] = useState();
  const [morningTime, setMorningTime] = useState('morning');
  const [eveningTime, setEveningTime] = useState('');
  const [error, setError] = useState('');

  const onMorningTimeChange = (date) => {
    setSiteVisitMorning(date);
  };
  const onEveningTimeChange = (date) => {
    setSiteVisitEvening(date);
  };

  const onClickScheduleSite = () => {
    if (
      !siteVisitEvening ||
      !siteVisitMorning ||
      !eveningTime ||
      !morningTime
    ) {
      setError('Please select time to visit');
    } else {
      setError('');
      const payload = {
        id: bidId,
        data: {
          siteVisitOne: {
            date: siteVisitEvening,
            time: eveningTime,
          },
          siteVisitTwo: {
            date: siteVisitMorning,
            time: morningTime,
          },
          status: 'scheduled',
        },
      };
      dispatch(updateBid(payload))
        .unwrap()
        .then((res) => {
          toast.success('Bid Updated Successfully!', { toastId: 'schedule' });
          dispatch(getBidsByStatus('shortListed'));
        })
        .catch((err) => {
          toast.error(err || 'SOmething went wrong!', {
            toastId: 'scheduleError',
          });
        });
    }
  };

  // submit final bid
  const onSubmitFinalBid = async (data) => {
    const uploadedFiles = await Promise.all(
      fileList.map(async (file) => {
        const url = await uploadFileToS3(file);
        return { url: url.url, name: file.name };
      })
    );
    const payload = {
      id: bid._id,
      data: {
        status: 'finalBidSubmitted',
        cost: {
          ...bid.cost,
          finalBid: {
            ...data,
            totalCost:
              Number(data.materialCost) +
              Number(data.laborCost) +
              Number(data.additionalCost),
            attachments: uploadedFiles,
          },
        },
      },
    };
    dispatch(updateBid(payload))
      .unwrap()
      .then((res) => {
        setFinalQuotePopupOpen(false);
        reset();
        dispatch(getBidsByStatus('scheduled'));
        toast.success('Final quote submitted successfully!', {
          toastId: 'finalQuote',
        });
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong', {
          toastId: 'finalQuoteError',
        });
      });
  };

  return (
    <div className='max-w-[840px] bg-white flex-column gap-2 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md'>
      <div className='row-flex items-center justify-between gap-4 md:gap-8'>
        <p
          className={`w-fit flex-none text-xs font-[600] rounded-[6px] p-[8px] capitalize ${type ? BID_TYPES[type].styles : 'text-green bg-lightGreen'
            }`}
        >
          {BID_TYPES[type].name}
        </p>
        <div className='row-flex gap-1'>
          <img
            src={LocationIcon}
            alt='location'
            className='w-[0.9rem] h-[1rem] md:w-[1.1rem] md:h-[1.2rem]'
          />
          <p className='text-xs md:text-sm text-gray'>
            {site?.addressOfSite ? site?.addressOfSite : ''}
          </p>
        </div>
      </div>
      <div className='row-flex items-center gap-2 mt-2'>
        <div className='h-[48px] w-[48px] rounded-full flex items-center justify-center bg-[#F9FFF1]'>
          <img src={DocumentIcon} alt='doc' className='w-[26px] h-[28px]' />
        </div>
        <div className='flex flex-col'>
          <p className='text-black text-base lg:text-lg capitalize font-[500]'>
            {site?.nameOfSite ? site?.nameOfSite : 'Bangash Holdings'}
          </p>
          <p className='text-darkGray2 text-sm lg:text-base capitalize font-[400]'>
            {customer?.name}
          </p>
        </div>
      </div>
      <p className='text-sm md:text-base text-gray3 mt-4'>
        Technology Type & Capacities
      </p>
      <div className='flex flex-wrap gap-4'>
        {site?.technologies?.length > 0
          ? site?.technologies?.map((item) => (
            <div className='row-flex items-center gap-2' key={item._id}>
              <p className='text-sm md:text-base text-[#5E6278] font-[500] capitalize'>
                {item?.technologyType === 'gas chp'
                  ? 'Gas CHP'
                  : item?.technologyType === 'biomass chp'
                    ? 'Biomass CHP'
                    : item?.technologyType}
              </p>
              <p
                className={`text-xs  ${item.isMatch
                  ? 'bg-secondary text-primary'
                  : 'bg-lightGray text-white'
                  } rounded-full py-1 px-1 font-[500]`}
              >
                {item?.capacity?.toFixed(1)} kW
              </p>
            </div>
          ))
          : null}
      </div>
      {type === 'scheduleVisit' ? (
        <div className='flex flex-wrap gap-4 md:gap-8 mt-4'>
          {error ? (
            <div className='flex items-center w-full'>
              <Alert message={error} type='error' showIcon />
            </div>
          ) : null}
          <div className='relative flex-column gap-1'>
            <p className='text-sm md:text-base text-gray3 font-[400]'>
              Select Site Visit Time 1
            </p>
            <div className='row-flex gap-2 items-center'>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#8DC63F',
                  },
                }}
              >
                <DatePicker
                  className='custom-date-picker'
                  // value={moment(siteVisitMorning)}
                  onChange={onMorningTimeChange}
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, 'days') >= current ||
                      moment().add(1, 'month') <= current
                    );
                  }}
                />
              </ConfigProvider>
              <div
                className='w-[150px] border-[1px] h-[33px] border-gray rounded-md flex flex-row items-center justify-between px-4  gap-2 cursor-pointer'
                onClick={() => seTimePopupOpen(!timePopupOpen)}
              >
                <p
                  className={`font-[300] capitalize ${morningTime
                    ? 'text-[#000000] text-sm'
                    : 'text-[#bfbfbf] text-[13px] '
                    }`}
                >
                  {morningTime ? morningTime : 'Choose Time'}
                </p>
                <img src={WatchGrayIcon} alt='icon' />
              </div>
              {timePopupOpen ? (
                <TimePopup
                  setPopupOpen={seTimePopupOpen}
                  setTime={setMorningTime}
                  value={morningTime}
                />
              ) : null}
            </div>
          </div>
          <div className='relative flex-column gap-1'>
            <p className='text-sm md:text-base text-gray3 font-[400]'>
              Select Site Visit Time 2
            </p>
            <div className='row-flex gap-2 items-center'>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#8DC63F',
                  },
                }}
              >
                <DatePicker
                  className='custom-date-picker'
                  onChange={onEveningTimeChange}
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, 'days') >= current ||
                      moment().add(1, 'month') <= current
                    );
                  }}
                />
              </ConfigProvider>
              <div
                className='w-[150px] border-[1px] h-[33px] border-gray rounded-md flex flex-row items-center justify-between px-4 gap-2 cursor-pointer'
                onClick={() => seTimePopupOpen2(!timePopupOpen2)}
              >
                <p
                  className={`font-[300] capitalize ${eveningTime
                    ? 'text-[#000000] text-sm'
                    : 'text-[#bfbfbf] text-[13px] '
                    }`}
                >
                  {eveningTime ? eveningTime : 'Choose Time'}
                </p>
                <img src={WatchGrayIcon} alt='icon' />
              </div>
            </div>
            {timePopupOpen2 ? (
              <TimePopup
                setPopupOpen={seTimePopupOpen2}
                setTime={setEveningTime}
                value={eveningTime}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {type === 'confirmedVisit' ? (
        <div className='flex flex-wrap gap-4 md:gap-8 xl:gap-12'>
          <div className='flex-column gap-1'>
            <p className='text-sm md:text-base text-gray font-[400]'>
              Selected Site Visit Time
            </p>
            <div className='flex-column gap-2'>
              <p className='row-flex items-center gap-2 text-sm md:text-base font-[500] text-[#5E6278]'>
                <span>
                  <img
                    src={CalendarIcon}
                    alt='icon'
                    className='h-[15px] w-[15px] ml-[1px]'
                  />
                </span>
                {new Date(bid?.confirmedSiteVisitTime.date).toDateString()}
              </p>
              <p className='row-flex items-center gap-2 text-sm md:text-base font-[500] text-[#5E6278] capitalize'>
                <span>
                  <img
                    src={WatchIcon}
                    alt='icon'
                    className='h-[20px] w-[18px]'
                  />
                </span>
                {bid?.confirmedSiteVisitTime.time} (
                {TIME[bid?.confirmedSiteVisitTime.time]})
              </p>
            </div>
          </div>
        </div>
      ) : null}
      {type === 'scheduleVisit' ? (
        <Button
          variant='bordered'
          text='Schedule Site Visit'
          className='ml-auto mt-6'
          loading={loading}
          onClick={onClickScheduleSite}
        />
      ) : type === 'confirmedVisit' ? (
        <Button
          variant='bordered'
          text='Submit Final Quote'
          className='ml-auto mt-6'
          onClick={() => setFinalQuotePopupOpen(true)}
        />
      ) : null}
      <FinalQuotePopup
        open={finalQuotePopupOpen}
        handleClose={handleFinalQuotePopupClose}
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmitFinalBid}
        setValue={setValue}
        errors={errors}
        fileList={fileList}
        setFileList={setFileList}
        loading={loading}
        watch={watch}
      />
    </div>
  );
};

export { BidInProcessCard };
