import React, { useState } from "react";
import Logo from "assets/svgs/Logo.svg";
import { Button, OtpInput } from "components/elements";
import { useNavigate } from "react-router-dom";
import { ANIMATE_RIGHT } from "utils/animations";
// import HalfCircle from "assets/images/half.png";
import { useDispatch, useSelector } from "react-redux";
import {
  installerForgetPassword,
  installerVerifyOtp,
} from "store/auth/authActions";
import { toast } from "react-toastify";

const Otp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const [otpCode, setOtpCode] = useState("");
  const [otpError, setOtpError] = useState("");

  const email = sessionStorage.getItem("email");

  const navigateToPath = (path) => {
    navigate(path);
  };

  const submitOtp = () => {
    setOtpError("");
    if (!otpCode) {
      setOtpError("OTP is required!");
    } else {
      dispatch(
        installerVerifyOtp({
          email,
          otp: otpCode,
        })
      )
        .unwrap()
        .then((_res) => {
          setOtpError("");
          toast.success("OTP verified successfully!", { toastId: "otp" });
          navigateToPath("/reset-password");
        })
        .catch((err) => {
          setOtpError(err);
        });
    }
  };

  const resendOtpCode = () => {
    dispatch(installerForgetPassword({ email }))
      .unwrap()
      .then((_res) => {
        toast.success("OTP resent successfully!", { toastId: "otpResent" });
      })
      .catch((err) => {
        toast.error(err || "Something went wrong!", { toastId: "otpError" });
      });
  };

  return (
    <div className="w-screen h-screen overflow-hidden">
      <div className="relative  flex-column items-center  mt-[3%] 1420:mt-[10%] px-4 py-4 md:py-0 gap-8 md:gap-15 1420:gap-18">
        <img src={Logo} alt="Logo" />
        <div className="flex-column items-center justify-center gap-3">
          <p
            className={`text-center text-xl md:text-[1.5rem] font-[600] ${ANIMATE_RIGHT}`}
          >
            Enter Verficiation Code
          </p>
          <p className="text-center text-sm md:text-lg text-darkGray">
            Please enter your 4-digits verification code to sent to your email{" "}
            <br />
            <span className="text-primary">{email}</span>
          </p>
        </div>
        <OtpInput otpCode={otpCode} setOtpCode={setOtpCode} />
        {otpError ? <p className="text-red-500">{otpError}</p> : null}
        <div className="flex-column items-center justify-center gap-3">
          <Button
            text="Submit"
            onClick={() => submitOtp()}
            className={ANIMATE_RIGHT}
            loading={loading}
          />
          <p className="text-xs md:text-sm text-darkGray font-medium">
            Didn't receive an email ?{" "}
            <span
              className={`text-primary ${
                loading ? "cursor-not-allowed" : "cursor-pointer"
              } `}
              onClick={() => resendOtpCode()}
            >
              Try Again
            </span>
          </p>
        </div>
      </div>
      {/* <img
        src={HalfCircle}
        alt="banner"
        className=" lg:block max-h-[250px]  1420:max-h-full absolute bottom-0 left-0 right-0 m-auto"
      /> */}
    </div>
  );
};

export { Otp };
