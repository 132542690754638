import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/elements';
import { BID_TYPES } from 'mock';
import { InitialBidPopup } from './InitialBidPopup';
import { createBidSchema } from 'validations';
import {
  createInvite,
  getAcceptedInvitesForInstaller,
} from 'store/invites/invitesAction';
import { getSites } from 'store/sites/sitesActions';
import { createBid } from 'store/bid/bidActions';
import { toast } from 'react-toastify';

// Images
import LocationIcon from 'assets/svgs/Location.svg';
import DocumentIcon from 'assets/svgs/Document.svg';
import DocumentEdit from 'assets/svgs/DocumentEdit.svg';
import Download from 'assets/svgs/Download.svg';

const COST_INFO = {
  materialCost: 'Material Cost',
  laborCost: 'Labor Cost',
  totalCost: 'Total Cost',
  additionalCost: 'Access Cost',
};

const BidCard = ({ type, site, initialBid, customer, finalBid }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.invite);
  const { loading: bidLoading } = useSelector((state) => state.bid);
  const installerId = localStorage.getItem('installerId');

  const [initialBidPopupOpen, setInitialBidPopupOpen] = useState(false);

  const handleInitialBidPopupClose = () => {
    setInitialBidPopupOpen(false);
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createBidSchema),
  });

  // submit bid request
  const onBidSubmit = (data) => {
    const initialBidDetails = {
      installerId: installerId,
      customerId: site?.customerId,
      siteId: site._id,
      bidRequestedBy: 'installer',
      status: 'pending',
    };
    dispatch(createInvite(initialBidDetails))
      .unwrap()
      .then((res) => {
        toast.success('Your bid request has been submitted successfully!', {
          toastId: 'bidRequest',
        });
        dispatch(getSites());
      })
      .catch((err) => {
        toast.error(err, { toastId: 'bidRequestError' });
      });
  };

  // submit initial bid
  const onInitialBidSubmit = (data) => {
    const { materialCost, laborCost, additionalCost } = data;
    const initialBidDetails = {
      installerId: installerId,
      customerId: site?.customerId,
      siteId: site._id,
      status: 'processing',
      cost: {
        initialBid: {
          materialCost: Number(materialCost),
          laborCost: Number(laborCost),
          additionalCost: Number(additionalCost),
          totalCost:
            Number(materialCost) + Number(laborCost) + Number(additionalCost),
        },
      },
    };

    dispatch(createBid(initialBidDetails))
      .unwrap()
      .then((res) => {
        toast.success(
          'Your initial bid has been submitted. The customer will review the bid and you will receive a notification about the outcome!',
          {
            toastId: 'initialBid',
          }
        );
        setInitialBidPopupOpen(false);
        dispatch(getAcceptedInvitesForInstaller(installerId));
        reset();
      })
      .catch((err) => {
        toast.error(err, { toastId: 'initialBidError' });
      });
  };

  return (
    <div className='max-w-[840px] bg-white flex-column gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md'>
      <div className='row-flex items-center justify-between gap-4 md:gap-8'>
        <p
          className={`w-fit flex-none text-xs font-[600] rounded-[6px] p-[8px] capitalize ${type ? BID_TYPES[type].styles : 'text-green bg-lightGreen'
            }`}
        >
          {BID_TYPES[type].name}
        </p>
        <div className='row-flex gap-1'>
          <img
            src={LocationIcon}
            alt='location'
            className='w-[0.9rem] h-[1rem] md:w-[1.1rem] md:h-[1.2rem]'
          />
          <p className='text-xs md:text-sm text-gray'>
            {site?.addressOfSite
              ? site?.addressOfSite
              : '123 Apartment, Floor 1 ABC 123 123 Apartment.'}
          </p>
        </div>
      </div>
      <div className='row-flex items-center gap-2 mt-4'>
        <div className='h-[48px] w-[48px] rounded-full flex items-center justify-center bg-[#F9FFF1]'>
          <img src={DocumentIcon} alt='doc' className='w-[26px] h-[28px]' />
        </div>
        <div className='flex flex-col'>
          <p className='text-black text-base lg:text-lg capitalize font-[500]'>
            {site?.nameOfSite ? site?.nameOfSite : 'Bangash Holdings'}
          </p>
          <p className='text-darkGray2 text-sm lg:text-base capitalize font-[400]'>
            {customer?.name || 'Customer Name'}
          </p>
        </div>
      </div>
      <p className='text-sm md:text-base text-gray3 mt-4'>
        Technology Type & Capacities
      </p>
      <div className='flex flex-wrap gap-4'>
        {site?.technologies?.length > 0
          ? site?.technologies?.map((item) => (
            <div className='row-flex items-center gap-2' key={item._id}>
              <p className='text-sm md:text-base text-[#5E6278] font-[500] capitalize'>
                {item?.technologyType === 'gas chp'
                  ? 'Gas CHP'
                  : item?.technologyType === 'biomass chp'
                    ? 'Biomass CHP'
                    : item?.technologyType}
              </p>
              <p
                className={`text-xs  ${item.isMatch
                  ? 'bg-secondary text-primary'
                  : 'bg-lightGray text-white'
                  } rounded-full py-1 px-1 font-[500]`}
              >
                {item?.capacity?.toFixed(1)} kW
              </p>
            </div>
          ))
          : [1, 2, 3, 4].map((item) => (
            <div key={item} className='row-flex items-center gap-2'>
              <p className='text-sm md:text-base text-[#5E6278] font-[500]'>
                Solar PV Panels
              </p>
              <p className='text-xs text-primary bg-secondary rounded-full py-1 px-2 font-[500]'>
                50,000 kW
              </p>
            </div>
          ))}
      </div>
      {type === 'bidInReview' && (
        <>
          <p className='text-sm md:text-base text-gray3 mt-4'>
            Initial Bid Details
          </p>
          <div className='flex flex-wrap gap-4'>
            {Object.keys(initialBid)
              .slice(0, 4)
              .map((item, i) => (
                <div className='flex flex-col items-center gap-0'>
                  <p className='text-sm md:text-base text-[#5E6278] font-[500]'>
                    {COST_INFO[item]}
                  </p>
                  <p className='text-lg text-primary py-1 px-2 font-[500]'>
                    £ {initialBid[item]}
                  </p>
                </div>
              ))}
          </div>
        </>
      )}
      {type === 'projectStarted' && (
        <div className='flex flex-col gap-4'>
          <p className='text-sm md:text-base text-gray3'>Final Bid Details</p>
          <div className='flex flex-wrap  gap-4'>
            {finalBid &&
              Object.keys(finalBid)
                .slice(0, 4)
                .map((item, i) => (
                  <div className='flex flex-col items-center gap-0'>
                    <p className='text-sm md:text-base text-[#5E6278] font-[500]'>
                      {COST_INFO[item]}
                    </p>
                    <p className='text-lg text-primary py-1 px-2 font-[500]'>
                      £ {finalBid[item]}
                    </p>
                  </div>
                ))}
          </div>
          {finalBid &&
            finalBid?.attachments?.length > 0 &&
            finalBid?.attachments?.map((item, i) => (
              <div key={i}>
                <p className='text-xs md:text-sm text-gray3'>Attachments</p>

                <div className='row-flex items-center gap-2 bg-background rounded-[4px] px-2 py-1 w-fit'>
                  <img src={DocumentEdit} className='h-4 w-4' alt=';icon' />
                  <a
                    href={item?.url}
                    download
                    target='_blank'
                    rel='noreferrer'
                    className='text-[10px] md:text-[12px] font-[400] text-[#6F6F7C]'
                  >
                    {item.name}
                  </a>
                  <img src={Download} className='h-4 w-4' alt='icon' />
                </div>
              </div>
            ))}
        </div>
      )}
      {type === 'opportunity' ? (
        <Button
          type='submit'
          variant='bordered'
          text='Request to Bid'
          className='ml-auto'
          loading={loading}
          onClick={onBidSubmit}
        />
      ) : type === 'invitation' ? (
        <Button
          variant='bordered'
          text='Submit Initial Bid'
          className='ml-auto'
          onClick={() => setInitialBidPopupOpen(true)}
        />
      ) : null}
      <InitialBidPopup
        open={initialBidPopupOpen}
        handleClose={handleInitialBidPopupClose}
        control={control}
        onSubmit={onInitialBidSubmit}
        handleSubmit={handleSubmit}
        loading={bidLoading}
        errors={errors}
        watch={watch}
      />
    </div>
  );
};

export { BidCard };
