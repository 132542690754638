import React from 'react';
import File from "assets/svgs/File.svg";
import { formatFileSize, getDisplayedFileName } from 'utils';

function FileBubble({ downloadHref, type, fileName, fileSize }) {

  const handleDownload = (event) => {
    event.preventDefault();
    window.location.href = downloadHref;
  };


  return (
    <a  onClick={handleDownload} target='_blank' download={fileName}>
    <div className={`flex gap-4 items-center p-8 rounded-tr-xl 
    shadow-md rounded-br-xl rounded-bl-xl ${type === 'send' ? 'bg-white' : 'bg-secondary'}`}>
      <span className={`${type === 'send' ? 'bg-secondary' : 'bg-white'} p-2 w-[40px] h-[40px] rounded-md flex items-center justify-center`}>
        <img src={File} alt="File Icon" />
      </span>
      <div className="flex-column">
        <span className="font-[600] text-base cursor-pointer text-black">{getDisplayedFileName(fileName,25)}</span>
        <span className="text-sm text-darkGray">{formatFileSize(fileSize)}</span>
      </div>
    </div>
    </a>
  );
}

export default FileBubble;
