import React, { useRef } from 'react';
import { useOnClickOutside } from 'react-calendar-toolkit';

const TimePopup = ({ value, setPopupOpen, setTime }) => {
  const timePopupRef = useRef();
  useOnClickOutside(timePopupRef, () => setPopupOpen(false));
  return (
    <div
      ref={timePopupRef}
      className='absolute bottom-10 flex-column gap-4 md:gap-8 w-[300px]  overflow-auto bg-[#faf8f3] shadow-xl p-4 rounded-[8px]'
    >
      <p className='text-black text-base md:text-xl font-[500]'>Select Time</p>
      <div className='flex-column gap-1 ml-8'>
        <label htmlFor='time1' className='flex flex-row items-center gap-2'>
          <input
            type='radio'
            name='time1'
            id='time1'
            className='h-4 w-4 appearance-none rounded-full border-2 border-[#8A8A8A] bg-white checked:bg-primary checked:border-[#8A8A8A] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2'
            value='Morning'
            checked={value === 'morning' ? true : false}
            onChange={() => {
              setTime('morning');
            }}
          />
          <label htmlFor='time1' className='text-lg'>
            Morning
          </label>
        </label>
        <p className='text-base text-[#98A2B3] ml-6'>8:00am - 12:00pm</p>
      </div>
      <div className='flex-column gap-1 ml-8'>
        <label htmlFor='time2' className='flex flex-row items-center gap-2'>
          <input
            id='time2'
            type='radio'
            name='time2'
            className='h-4 w-4 appearance-none rounded-full border-2 border-[#8A8A8A] bg-white checked:bg-primary checked:border-[#8A8A8A] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2'
            value='Evening'
            checked={value === 'evening' ? true : false}
            onChange={() => setTime('evening')}
          />
          <label htmlFor='time2' className='text-lg'>
            Evening
          </label>
        </label>
        <p className='text-base text-[#98A2B3] ml-6'>01:00pm - 05:00pm</p>
      </div>
      <div className='flex flex-row items-center ml-auto gap-4'>
        <p
          className='cursor-pointer text-primary'
          onClick={() => setPopupOpen(false)}
        >
          Cancel
        </p>
        <p
          className='cursor-pointer text-primary'
          onClick={() => setPopupOpen(false)}
        >
          Ok
        </p>
      </div>
    </div>
  );
};

export { TimePopup };
