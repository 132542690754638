import React from "react";

const ArrowRight = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.16797"
        y="10.834"
        width="1.66667"
        height="11.6667"
        rx="0.833333"
        transform="rotate(-90 4.16797 10.834)"
        fill={color}
      />
      <path
        d="M9.40944 14.4114C9.084 14.7368 9.084 15.2645 9.40944 15.5899C9.73488 15.9153 10.2625 15.9153 10.588 15.5899L15.588 10.5899C15.9034 10.2744 15.9145 9.76644 15.613 9.43755L11.0297 4.43755C10.7187 4.09828 10.1915 4.07536 9.85226 4.38636C9.51299 4.69735 9.49007 5.22449 9.80107 5.56376L13.8452 9.97559L9.40944 14.4114Z"
        fill={color}
      />
    </svg>
  );
};

export { ArrowRight };
