import React, { useEffect, useState, useRef } from 'react';
import ChatHeader from './ChatHeader';
import TextArea from './TextArea';
import UsersList from './UsersList';
import { useDispatch, useSelector } from 'react-redux';
import { addMessageToChat } from 'store/chat/chatActions';
import Chat from 'assets/svgs/chat.svg';
import {
  clearMessageCount,
  setSelectedChatParticipant,
} from 'store/chat/chatSlice';
import IndivualChat from './indivualChat';
import { INSTALLER_DATA } from 'mock/data';

function ChatLayout() {
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();

  const containerHeightClass = files.length
    ? 'chat-container-lg'
    : 'chat-container-sm';

  const { chat, messageCount, selectedChatParticpant, chatThreads } =
    useSelector((state) => state.chat);

  const userId = localStorage.getItem('user_id');
  const chatContainerRef = useRef(null);
  const selectedChat = Object.keys(selectedChatParticpant).length > 0;
  const oldMessagesLength = useRef(chat?.messages ? chat.messages.length : 0);

  useEffect(() => {
    if (selectedChat) {
      const currentMessagesLength = chat?.messages ? chat.messages.length : 0;
      if (currentMessagesLength > oldMessagesLength.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef?.current?.scrollHeight;
      }
      oldMessagesLength.current = currentMessagesLength;
    }
    return () => {
      setSelectedChatParticipant({});
    };
  }, [chat?.messages]);

  useEffect(() => {
    if (selectedChat) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    return () => {
      setSelectedChatParticipant({});
    };
  }, []);

  useEffect(() => {
    if (messageCount > 0) {
      dispatch(clearMessageCount());
    }
  }, [messageCount]);

  const EmptyContainer = () => {
    return (
      <div className='flex justify-center gap-7  w-full empty-container items-center flex-col'>
        <img className='w-[90px] h-[90px]' src={Chat} alt='' />
        {chatThreads.length === 0 ? (
          <p className='text-primary'>No Chat Available</p>
        ) : (
          <p className='text-primary'>No Chat Selected</p>
        )}
      </div>
    );
  };

  const handleMessageSend = (message) => {
    if (message.length > 0 || files.length > 0)
      dispatch(
        addMessageToChat({
          id: selectedChatParticpant.chatId,
          payload: {
            senderId: userId,
            text: message,
            files: files,
          },
        })
      )
        .unwrap()
        .then((res) => {
          setFiles([]);
          document.getElementById('fileInput').value = '';
        });
  };

  return (
    <>
      <p className='font-medium py-4 text-[#475466] pb-1'>
        {INSTALLER_DATA.messages['mainText']}
      </p>
      <div className='flex flex-col lg:flex-row-reverse gap-5 w-full'>
        <UsersList />
        <div className='w-full flex-column lg:min-w-[600px] xl:min-w-[700px] relative mt-4 '>
          {selectedChat ? (
            <>
              <ChatHeader />
              <IndivualChat
                containerHeightClass={containerHeightClass}
                chatContainerRef={chatContainerRef}
              />
              <TextArea
                files={files}
                setFiles={setFiles}
                handleMessageSend={(message) => handleMessageSend(message)}
              />
            </>
          ) : (
            <EmptyContainer />
          )}
        </div>
      </div>
    </>
  );
}

export default ChatLayout;
