import {
  FinalBidSubmitted,
  InitialBid,
  ProjectDetails,
  SiteVisitDetails,
} from 'components/customer';
import { PageLoader } from 'components/elements';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const CustomerProjectsStarted = () => {
  const { id } = useParams();
  const { loading, startedProjects } = useSelector((state) => state.bid);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const project = startedProjects.find((item) => item._id === id);
    setSelectedProject(project);
  }, [id, startedProjects]);


  return (
    <>
      <div className='flex flex-col gap-2 py-4'>
        <p className='text-base font-medium text-darkGray2'>
          Here you can find all the projects that have been started or completed
          using OnGen Marketplace.
        </p>
      </div>
      {loading ? (
        <div className='w-full h-[90vh] m-auto flex items-center justify-center'>
          <PageLoader />
        </div>
      ) : (
        <div className='relative py-4 md:py-8 px-1'>
          <div className='relative z-[1] flex-column gap-8'>
            <ProjectDetails
              showMatchCheck
              installerId={
                selectedProject?.installerId
              } site={selectedProject?.siteId} />
            <InitialBid bid={selectedProject} />
            <SiteVisitDetails bid={selectedProject} />
            <FinalBidSubmitted bid={selectedProject} />
          </div>

          {/* Vertical Green Line */}
          <div className='absolute top-2 left-[38px] z-[0] w-[1px] h-[80%] bg-primary'>
            <div className='absolute top-0 left-1/2 transform -translate-x-1/2'>
              <div className='w-2 h-2 rounded-full bg-primary'></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { CustomerProjectsStarted };
