import {
  installerForgetPassword,
  installerLogin,
  installerResetPassword,
  installerVerifyOtp,
  createAccount,
  customerLogin,
  verifyToken,
} from './authActions';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  loading: false,
  error: '',
  forgetPasswordError: '',
  user: {},
  token: '',
  installers: [],
  tokenValid: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAccount.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createAccount.rejected, (state) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(installerLogin.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(installerLogin.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = action.payload.installer;
        state.error = '';
        state.loading = false;
      })
      .addCase(installerLogin.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(installerForgetPassword.pending, (state) => {
        state.loading = true;
        state.forgetPasswordError = '';
      })
      .addCase(installerForgetPassword.fulfilled, (state) => {
        state.forgetPasswordError = '';
        state.loading = false;
      })
      .addCase(installerForgetPassword.rejected, (state, action) => {
        state.forgetPasswordError = action.payload;
        state.loading = false;
      })
      .addCase(installerResetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(installerResetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(installerResetPassword.rejected, (state) => {
        state.loading = false;
      })
      .addCase(installerVerifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(installerVerifyOtp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(installerVerifyOtp.rejected, (state) => {
        state.loading = false;
      })
      .addCase(customerLogin.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(customerLogin.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = action.payload.customer;
        state.error = '';
        state.loading = false;
      })
      .addCase(verifyToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyToken.fulfilled, (state) => {
        state.loading = false;
        state.tokenValid = true;
      })
      .addCase(verifyToken.rejected, (state) => {
        state.loading = false;
        state.tokenValid = false;
      })
      .addCase(customerLogin.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default authSlice.reducer;
