import React, { useEffect, useState } from "react";
import { BorderHeading } from "components/macros";
import { Checkbox, Input, Button } from "components/elements";
import { REGIONS } from "mock";
import Dragger from 'antd/es/upload/Dragger';
import DropzoneIcon from 'assets/svgs/DropzoneIcon.svg';
import FileUpload from 'components/macros/FileUpload';
const FieldsContainer = ({ heading, children }) => {
  return (
    <>
      <BorderHeading heading={heading} />
      <section className="py-7 flex flex-col gap-7">{children}</section>
    </>
  );
};

function ProfileForm({
  control,
  techs,
  getValues,
  setValue,
  errors,
  loading,
  extra,
  mode,
  watch,
  fileList,
  setFileList
}) {
  const [regionChecked, setRegionChecked] = useState([]);
  const description = watch('description')


  useEffect(() => {
    const regionValues = getValues("region") || [];
    setRegionChecked(regionValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("region")]);

  const [_technologiesChecked, setTechnologiesChecked] = useState([]);

  useEffect(() => {
    const technologiesValues = getValues("technologies") || [];
    const checkedTechnologies = technologiesValues.map(
      (tech) => tech.technologyType
    );
    setTechnologiesChecked(checkedTechnologies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("technologies")]);


  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };




  const onChange = (name, checked) => {
    let updatedTechnologies = [...getValues("technologies")];


    if (checked) {
      if (!updatedTechnologies.some((tech) => tech.technologyType === name)) {
        updatedTechnologies.push({
          technologyType: name,
          minCapacity: "",
          maxCapacity: "",
        });
      }
    } else {
      updatedTechnologies = updatedTechnologies.filter(
        (tech) => tech.technologyType !== name
      );
    }

    setTechnologiesChecked(
      updatedTechnologies.map((tech) => tech.technologyType)
    );
    setValue("technologies", updatedTechnologies);
  };

  const handleRegionCheckboxChange = (name, checked) => {
    let region = [...regionChecked];

    if (checked) {
      if (!region.includes(name)) {
        region.push(name);
      }
    } else {
      region = region.filter((regionName) => regionName !== name);
    }

    setRegionChecked(region);
    setValue("region", region);
  };



  const INPUT_CLASS =
    "flex flex-col sm:!flex-row !sm:justify-between sm:items-center gap-2";
  return (
    <div className="px-[5px] lg:pl-[50px] lg:pr-[30px]">
      <FieldsContainer heading="Installer Information">
        <Input
          divClassName={INPUT_CLASS}
          name="name"
          label="Representative Name"
          className="min-w-[400px] w-full lg:min-w-[480px]"
          control={control}
          error={errors && errors?.name?.message}
        />
        <Input
          divClassName={INPUT_CLASS}
          name="email"
          label="Email Address"
          className="min-w-[400px] w-full lg:min-w-[480px]"
          control={control}
          error={errors && errors?.companyName?.message}
        />


      </FieldsContainer>
      <FieldsContainer heading="Company Information">
        <Input
          divClassName={INPUT_CLASS}
          name="companyName"
          label="Company Name"
          className="min-w-[400px] w-full lg:min-w-[480px]"
          control={control}
          error={errors && errors?.companyName?.message}
        />
        <Input
          divClassName={INPUT_CLASS}
          name="address"
          label="Address"
          className="min-w-[400px] w-full lg:min-w-[480px]"
          control={control}
          error={errors && errors?.address?.message}
        />
        <Input
          divClassName={INPUT_CLASS}
          name="postcode"
          label="Postcode"
          className="min-w-[400px] w-full lg:min-w-[480px]"
          control={control}
          error={errors && errors?.postcode?.message}
        />
        <Input
          divClassName={INPUT_CLASS}
          name="website"
          label="Website"
          className="min-w-[400px] w-full lg:min-w-[480px]"
          control={control}
          error={errors && errors?.website?.message}
        />
        <div className='flex flex-col sm:flex-row gap-3 justify-between'>
          <p className=' text-xs xl:text-sm capitalize font-medium '>Company description</p>
          <textarea
            name="email"
            placeholder='Add a description and any relevant documents about your organization'
            label="Representative description"
            className="w-[480px] text-sm h-[100px] bg-background p-2 rounded-[10px]"
            value={description}
            onChange={(e) => {
              setValue('description', e.target.value)
            }}
          />
        </div>
        <FileUpload
          fileList={fileList}
          setFileList={setFileList}
          maxSize={20}
          maxFiles={5}
          label='Upload Document'
          disablePreview={true}
        />
      </FieldsContainer>
      <div className="mb-4 md:mb-8">
        <BorderHeading heading="Available Technologies and Capacities" />
        <div className="grid-container grid-container-gap-xl mt-8 bg-[#EEF1F5] p-4">
          {techs.map(({ title, name, displayed, key }, index) => {
            const technologies = getValues("technologies") || [];
            const isChecked = technologies.some(
              (tech) => tech.technologyType === name
            );
            const technology = technologies.find(
              (tech) => tech.technologyType === name
            );

            return (
              <div className="flex-column gap-4">
                <Checkbox
                  name="technologies"
                  title={title}
                  control={control}
                  key={index}
                  onChange={(e) => {
                    onChange(name, e.target.checked);
                  }}
                  checked={isChecked}
                />
                {isChecked ? (
                  <div>
                    <Input
                      label="Min. Project Capacity (KW)"
                      name={`technologies[${technologies.indexOf(
                        technology
                      )}].minCapacity`}
                      variant="white"
                      control={control}
                      labelType="normal"
                      className="1420:h-[50px]"
                      error={
                        errors &&
                        errors?.technologies?.[technologies.indexOf(technology)]
                          ?.minCapacity?.message
                      }
                    />
                    <Input
                      label="Max. Project Capacity (KW)"
                      name={`technologies[${technologies.indexOf(
                        technology
                      )}].maxCapacity`}
                      variant="white"
                      control={control}
                      labelType="normal"
                      className="1420:h-[50px]"
                      error={
                        errors &&
                        errors?.technologies?.[technologies.indexOf(technology)]
                          ?.maxCapacity?.message
                      }
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <BorderHeading heading="Supplied Regions" />
        <p className="text-xs xl:text-sm text-red-500 mt-2">
          {errors && errors?.region?.message}
        </p>
        <div className="grid-container grid-container-gap-xl mt-5 bg-[#EEF1F5] p-4">
          {REGIONS.map(({ title, name }, index) => (
            <Checkbox
              name="region"
              title={title}
              key={index}
              onChange={(e) => {
                handleRegionCheckboxChange(name, e.target.checked);
              }}
              checked={regionChecked.includes(name)} // Set checked state based on local state
              control={control}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-end mt-2 w-full">
        <Button text="Save" loading={loading} className="min-w-[81px]" />
      </div>
    </div>
  );
}

export { ProfileForm };
