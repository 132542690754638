import { message } from "antd";
import { toast } from "react-toastify";
import uploadFileToS3 from "utils/s3Upload";
const { createAsyncThunk } = require("@reduxjs/toolkit");
const { axiosInstance } = require("api/axios");

export const getInstallerById = createAsyncThunk(
  "installer/getById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/installers/${id}`);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const updateInstallerProfile = createAsyncThunk(
  "installer/updateProfile",
  async (updatedProfile, { rejectWithValue }) => {
    try {
      let finalData;
      let newImagesUrl = [];
      console.log("updatedProfile", updatedProfile)

      let id = localStorage.getItem("user_id");
      if (updatedProfile?.image) {
        const profileImage = await uploadFileToS3(updatedProfile.image);
        finalData = {
          ...updatedProfile,
          profileImage: profileImage.url,
        };
      } else {
        finalData = updatedProfile;
      }

      const newImages = updatedProfile?.extras?.images.filter(
        (file) => typeof file !== 'string'
      );


      if (newImages.length >= 0) {
        for (const attachment of newImages) {
          if (attachment) {
            const uploadedFile = await uploadFileToS3(attachment);
            newImagesUrl.push(uploadedFile.url);
          }
        }
      }

      if (newImagesUrl && newImagesUrl.length > 0 && newImages) {
        // Check if finalData.extras is defined before accessing its images property
        finalData.extras = finalData.extras || {};
        finalData.extras.images = (finalData.extras.images || []).concat(newImagesUrl[0]);
        finalData.extras.images = finalData.extras.images.filter(
          (item) => typeof item == 'string'
        );
      }
      else {
        finalData.extras = finalData.extras || {};
        console.log({ b: finalData?.extras?.images })
        finalData.extras.images = finalData?.extras?.images || []
      }



      const response = await axiosInstance.put(`/installers/${id}`, { ...finalData });
      toast.success("Profile Updated Successfully", {
        toastId: "profileUpdate",
      });
      return response
    } catch (err) {
      console.log("err", err)
      toast.error(err?.data?.message, { toastId: "profileUpdateError" });
      return rejectWithValue(err?.data?.message);
    }
  }
);


export const getInstallerNotifications = createAsyncThunk(
  "notification/get",
  async (_, { rejectWithValue }) => {
    // get notification for current installer
    const id = localStorage.getItem("user_id");
    try {
      const response = await axiosInstance.get(
        `/installer/notifications/${id}`
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "getDashboardData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/dashboard`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);
