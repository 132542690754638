import React, { useState } from "react";
import { Controller } from "react-hook-form";
import EyeIcon from "assets/svgs/EyeIcon.svg";
import EyeIconOff from "assets/svgs/EyeIconOff.svg";
import { checkPasswordStrength } from "utils";

const PasswordInput = ({
  placeholder,
  variant,
  className,
  name,
  control,
  setValue,
  label,
  passwordStrength,
  error,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(true);
  const [passStrengthScore, setPassStrengthScore] = useState(0);

  const onPasswordChange = (e) => {
    setPassStrengthScore(checkPasswordStrength(e.target.value));
    if (setValue) setValue(name, e.target.value);
  };

  return (
    <div className="w-full flex flex-col items-start relative md:gap-1">
      <label className="text-xs xl:text-sm capitalize font-medium">
        {label}
      </label>
      <div
        className={`w-full h-[45px] 1420:h-[55px] flex flex-row items-center justify-between gap-1  rounded-[9px] focus:border-[1px] focus:border-primary ${
          variant === "white"
            ? "bg-white border-background border-[2px]"
            : "bg-background"
        } ${className}`}
      >
        <Controller
          control={control}
          defaultValue=""
          name={name}
          render={({ field: { onChange, ...field } }) => (
            <input
              type={showPassword ? "password" : "text"}
              placeholder={placeholder}
              className={`w-full h-full pl-2 py-2 text-black text-sm lg:text-base rounded-[8px] bg-transparent focus:outline-none`}
              control={control}
              onChange={(e) => onPasswordChange(e)}
              {...rest}
              {...field}
            />
          )}
        />
        <img
          src={showPassword ? EyeIcon : EyeIconOff}
          className="absolute right-3  h-5 w-5 cursor-pointer"
          alt="icon"
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      {passwordStrength ? (
        <div className="flex gap-2 text-xs mt-1">
          {[1, 2, 3, 4].map((item) => (
            <span
              key={item}
              className={`rounded-[5px] py-[3px] w-16 ${
                passStrengthScore < item ? "bg-lightGray" : "bg-[#50CD89]"
              } `}
            />
          ))}
        </div>
      ) : null}
      {error ? (
        <p className="text-xs lg:text-sm text-red-500">{error}</p>
      ) : null}
    </div>
  );
};

export { PasswordInput };
