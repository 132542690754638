import React from 'react';
import DocumentTextIcon from 'assets/svgs/DocumentText.svg';
import moment from 'moment';

const ProjectInfo = ({ selected, site, onClick }) => {
  return (
    <div
      className={`w-[275px] flex flex-row gap-4 p-4 rounded-md shadow-md cursor-pointer ${selected ? 'bg-[#EFFFDB]' : 'bg-white'
        }`}
      onClick={onClick}
    >
      <img src={DocumentTextIcon} alt='icon' className='w-[24px] h-[26px]' />

      <div className='flex-column'>
        <p
          className={`text-[15px]   ${selected ? 'text-[#344054]' : 'text-[#5E6278]'
            }`}
        >
          {site?.nameOfSite || 'Bangash Holdings'}
        </p>
        <p className='text-xs text-[#98A2B3]'>
          {site?.addressOfSite || '123 Apartment, Floor 1 ABC 456'}
        </p>
        <p className='text-xs text-[#98A2B3]'>
          {moment(site?.createdAt).format('DD/MM/YYYY') ||
            '123 Apartment, Floor 1 ABC 456'}
        </p>
      </div>
    </div>
  );
};

export { ProjectInfo };
