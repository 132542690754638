import React from 'react';
import { Button } from 'components/elements';
import { useLocation, useNavigate } from 'react-router-dom';


function ProfileButtons({mode, loading}) {
  const location = useLocation()
  const navigate = useNavigate()
  const isProfilePath = location.pathname === '/dashboard/profile';
  const isEditProfilePath = location.pathname === '/dashboard/profile/edit';

  const styles = {
    buttonGroup: "flex-column sm:row-flex gap-4",
    cancelButton: "min-w-[98px]",
    saveButton: "min-w-[81px]",
    editButton: "min-w-[125px]",
  }

  if (isProfilePath || isEditProfilePath) {
    if (mode === 'edit') {
      return (
        <div className={styles.buttonGroup}>
          <Button
            variant="bordered"
            text="Cancel"
            onClick={() => navigate('/dashboard/profile')}
            className={styles.cancelButton}
          />
          <Button
            text="Save"
            loading={loading}
            className={styles.saveButton}
          />
        </div>
      );
    } else {
      return (
        <Button
          variant="bordered"
          text="Edit Profile"
          onClick={() => navigate('/dashboard/profile/edit')}
          className={styles.editButton}
        />
      );
    }
  } else {
    return null;
  }
}

export default ProfileButtons;
