import { AuthLayout } from "components/auth";
import { Button, Input } from "components/elements";
import React from "react";
import { useForm } from "react-hook-form";
import Logo from "assets/svgs/Logo.svg";
import { useNavigate } from "react-router-dom";
import { ANIMATE_TOP_LEFT } from "utils/animations";
import { useDispatch, useSelector } from "react-redux";
import { installerForgetPassword } from "store/auth/authActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { installerForgetPasswordSchema } from "validations";
import { Alert } from "antd";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, forgetPasswordError } = useSelector((state) => state.auth);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(installerForgetPasswordSchema),
  });

  const navigateToPath = (path) => {
    navigate(path);
  };
  const onSubmit = (data) => {
    dispatch(installerForgetPassword({ email: data.email }))
      .unwrap()
      .then((_res) => {
        sessionStorage.setItem("email", data.email);
        navigateToPath("/verify-otp");
      })
      .catch((err) => {
        console.log("Err => ", err);
      });
  };

  return (
    <AuthLayout>
      <div className="w-full max-w-[500px] m-auto flex-column items-start justify-center gap-6 md:gap-8 px-4 py-12">
        <img src={Logo} alt="Logo" className="md:hidden w-[60%] m-auto mb-6" />
        <div
          className={`w-full flex flex-col items-center md:items-start gap-2 ${ANIMATE_TOP_LEFT}`}
        >
          <p className="text-xl md:text-3xl font-[600]">Forgot Password</p>
          <p className="text-sm text-darkGray">
            Already have an account?{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => navigateToPath("/login")}
            >
              Sign In
            </span>
          </p>
        </div>
        {forgetPasswordError ? (
          <div className="flex items-center justify-center w-full">
            <Alert message={forgetPasswordError} type="error" showIcon />
          </div>
        ) : null}
        <Input
          variant="white"
          name="email"
          label="Your Email"
          control={control}
          error={errors.email ? errors.email.message : ""}
        />
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          text="Reset Password"
          className={`w-full ${ANIMATE_TOP_LEFT}`}
          loading={loading}
        />
      </div>
    </AuthLayout>
  );
};

export { ForgotPassword };
