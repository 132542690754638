import {
  Menu,
  Stack,
  CheckBox,
  Flag,
  Notification,
  Message,
} from 'assets/svgs/icons';

export const INSTALLER_ROUTER = [
  {
    key: '1',
    icon: <Menu />,
    label: 'Dashboard',
    path: '/dashboard',
  },
  {
    key: '2',
    icon: <Stack />,
    label: 'Get Started',
    children: [
      {
        key: '21',
        label: 'Invitations',
        path: '/dashboard/invitations',
      },
      {
        key: '22',
        label: 'Opportunities',
        path: '/dashboard/opportunities',
      },
    ],
  },
  {
    key: '3',
    icon: <CheckBox />,
    label: 'Bid In Process',
    children: [
      {
        key: '31',
        label: 'Bid In Review',
        path: '/dashboard/bidInReview',
      },
      {
        key: '32',
        label: 'Schedule Site Visit',
        path: '/dashboard/scheduleSiteVisit',
      },
      {
        key: '33',
        label: 'Confirmed Site Visit',
        path: '/dashboard/confirmedSiteVisit',
      },
    ],
  },
  // {
  //   key: '4',
  //   icon: <Flag />,
  //   label: 'Project Started',
  //   children: [
  //     {
  //       key: '41',
  //       label: 'Project 1',
  //       path: '/dashboard/projects',
  //     },
  //   ],
  // },
  {
    key: '5',
    icon: <Notification />,
    label: 'Notifications',
    path: '/dashboard/notifications',
  },
];
